import { AxiosResponse } from "axios";
import { axiosInstance } from "../../config/axios.config";

// type BaseResponse = {
//   data: any;
//   errors: any;
//   isSucceeded: boolean;
// };

type actionType = {
  line: [];
  year: string;
};

const baseUrl = "chart";

const chartApi = {
  getNumberJuniorSenior: async ({
    line,
  }: actionType): Promise<AxiosResponse<any, any>> => {
    let url = baseUrl;
    if (line) {
      url += `/chartOfLine?lineName=${line.join("&lineName=")}`;
    } else url += "/chartOfLine";

    // const url = "chart/chartOfLine";
    const response = (await axiosInstance.get)<any>(url);
    return response;
  },

  getOnboardResign: async ({
    year,
  }: actionType): Promise<AxiosResponse<any, any>> => {
    let url = baseUrl;
    url += `/chartOnBoardOutDate?year=${year}`;

    // const url = "chart/chartOfLine";
    const response = (await axiosInstance.get)<any>(url);
    return response;
  },

  getPDistribution: async (): Promise<AxiosResponse<any, any>> => {
    return (await axiosInstance.get)<any>(`${baseUrl}/pDistribution`);
  },
};

export { chartApi };
