import { createSlice } from "@reduxjs/toolkit";

type stateType = {
  getAllCertificatesLoading: boolean;
  getAllCertificatesResponse: any;

  getPaginationResponse: any;
};

const initialState: stateType = {
  getAllCertificatesLoading: false,
  getAllCertificatesResponse: null,
  getPaginationResponse: null,
};

const certificatesSlice = createSlice({
  name: "certificates",
  initialState,
  reducers: {
    //Get all Cert
    getAllCertRequest(state, _payload) {
      state.getAllCertificatesLoading = true;
    },
    getAllCertSuccess(state, { payload }) {
      state.getAllCertificatesResponse = payload.data;
      state.getPaginationResponse = payload.metaData;
      state.getAllCertificatesLoading = false;
    },
    getAllCertFailure(state) {
      state.getAllCertificatesLoading = false;
    },
  },
});

const { actions, reducer } = certificatesSlice;
export { actions as certificatesAction, reducer as certificatesReducer };
