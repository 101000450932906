import { createSlice } from "@reduxjs/toolkit";

type stateType = { getAllResponse: any; getAllResponseLoading: boolean };

const initialState: stateType = {
  getAllResponse: [],
  getAllResponseLoading: false,
};

const todoTaskSlice = createSlice({
  name: "todoTask",
  initialState,
  reducers: {
    getAllRequest(state) {
      state.getAllResponseLoading = true;
    },
    getAllSuccess(state, { payload }) {
      state.getAllResponse = payload.data;
      state.getAllResponseLoading = false;
    },
    getAllFailure(state) {
      state.getAllResponseLoading = false;
    },
  },
});

const { actions, reducer } = todoTaskSlice;

export { actions as todoTaskActions, reducer as todoTaskReducer };
