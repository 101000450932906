import { Link } from "react-router-dom";
import styled from "styled-components";

const HeaderContainer = styled.div`
  height: var(--header-height);
  display: flex;
  align-items: center;

  &.topbar {
    position: fixed;
    top: 0;
    left: 0px;
    right: 0;
    background: #fff;
    border-bottom: 1px solid rgb(228 228 228 / 0%);
    z-index: 10;
    -webkit-box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%),
      0 0px 6px 0 rgb(206 206 238 / 54%);
    box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%),
      0 0px 6px 0 rgb(206 206 238 / 54%);
  }

  @media screen and (max-width: 992px) {
    &.topbar {
      left: 0 !important;
    }
  }
`;

const NavbarContainer = styled.nav`
  &&& {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 30px;
    .logo-area {
      width: 228px;
      padding-right: 30px;
      display: flex;
      align-items: center;
      gap: 1rem;

      .logo-dashboard {
        height: 60px;
      }
    }
    @media (max-width: 480px) {
      .logo-area {
        width: 100px;
      }
      .logo-area .logo-dashboard {
        display: none;
      }
    }
  }
`;

const MobileToggleMenu = styled.div`
  display: flex;
  font-size: 26px;
  color: #404142;
  cursor: pointer;

  @media screen and (max-width: 992px) {
    display: block;
  }
`;

const HeaderSearch = styled.div`
  flex-grow: 1;

  @media screen and (max-width: 992px) {
    display: flex;
    justify-content: flex-end;
  }
`;

const HeaderSearchBarBox = styled.div`
  width: 45%;
  position: relative;

  @media (max-width: 992px) {
    width: 100%;
  }
`;

const SearchControl = styled.input`
  background-color: #f7f7ff;
  border: 1px solid #e9e9f6;
  padding-left: 40px !important;

  @media screen and (max-width: 992px) {
    .search-control {
      background-color: #fff;
      border: 1px solid #f2efef00;
    }
  }
`;

const FormControl = styled(SearchControl)`
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: #212529;
  background-clip: padding-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  @media screen and (max-width: 992px) {
    padding-left: 4px !important;
  }
`;

const IconSearchShow = styled.span`
  font-size: 18px;
  position: absolute;
  left: 15px;
  top: 60%;
  transform: translateY(-50%);

  @media screen and (max-width: 992px) {
    display: none;
  }
`;

const TopMenu = styled.div`
  margin-left: auto;

  @media screen and (max-width: 576px) {
    display: none;
  }
`;

const NavbarList = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
`;

const NavbarLink = styled.a`
  color: #252323;
  font-size: 22px;

  &.nav-icon-padding {
    padding: 0 12px !important;
  }

  &.nav-position {
    position: relative;
  }

  &.nav-link {
    display: block;
    padding: 0.5rem 0.4rem;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }

  &.nav-user {
    display: flex !important;
    align-items: center;
  }

  &.nav-link .nav-badge svg {
    font-size: 18px;
    vertical-align: bottom;
  }
`;

const NavbarItem = styled.li`
  position: relative;

  &.mobile-search-icon {
    display: none;
  }

  @media screen and (max-width: 992px) {
    &.mobile-search-icon {
      display: block;
    }
  }
`;

const AlertCount = styled.span`
  position: absolute;
  top: -5px;
  left: 22px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  background: #f62718;
`;

const UserBox = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  border-left: 1px solid #f0f0f0;
  margin-left: 16px;
`;

const UserImg = styled.img`
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border: 0 solid #e5e5e5;
  padding: 0;
  vertical-align: middle;
  object-fit: cover;
`;

const UserInfo = styled.div`
  padding-left: 1rem;

  .user-name {
    font-size: 15px;
    font-weight: 500;
    color: #413c3c;
    margin-bottom: 0;
    text-transform: uppercase;
  }

  .user-job {
    font-size: 13px;
    color: #a9a8a8;
    margin-bottom: 0;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const MsgHeader = styled.div`
  padding: 8px 0;
  text-align: left;
  display: flex;
  align-items: center;

  .msg-header-title {
    font-size: 15px;
    color: #1c1b1b;
    margin-bottom: 0;
    font-weight: 500;
  }

  .msg-header-clear {
    font-size: 12px;
    color: #585858;
    margin-bottom: 0;
    padding: 4px 6px;
  }

  .msg-header-clear:hover {
    background-color: #ede8e8;
  }

  .ms-auto {
    margin-left: auto;
  }
`;

const MsgContainer = styled.div`
  display: flex;
  align-items: center;

  .user-online {
    position: relative;
  }

  .user-online::after {
    content: "";
    position: absolute;
    bottom: 1px;
    right: 17px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    box-shadow: 0 0 0 2px #fff;
    background: #16e15e;
  }

  .msg-avatar {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-right: 15px;
    vertical-align: middle;
  }

  .msg-info {
    flex-grow: 1;
  }

  .msg-name {
    font-size: 14px;
    margin-bottom: 0;
  }

  .msg-time {
    font-size: 12px;
    margin-bottom: 0;
    color: #919191;
    float: right;
  }

  .msg-description {
    font-size: 13px;
    margin-bottom: 0;
    color: #c1c1c1;
  }
`;

const NotiContainer = styled(Link)`
  display: flex;
  align-items: center;

  .notify {
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-size: 22px;
    text-align: center;
    border-radius: 50%;
    background-color: #f1f1f1;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .noti-info {
    flex-grow: 1;
    position: relative;
  }

  .noti-more {
    position: absolute;
    top: 20px;
    right: 10px;
    background-color: #fff;
    display: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #ccc;
    text-align: center;
    font-size: 24px;
    line-height: 14px;
    z-index: 99;
  }

  .noti-more:hover {
    background-color: #e3dfdf;
  }

  .noti-name {
    font-size: 14px;
    margin-bottom: 0;
  }

  .noti-unread {
    color: #050505;
  }

  .noti-read {
    color: #b0b3b8;
  }

  .noti-time {
    font-size: 12px;
    margin-bottom: 0;
    float: right;
  }

  .noti-description {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
    margin-top: 4px;
  }

  .noti-content {
    display: block; /* Fallback for non-webkit */
    display: -webkit-box;
    height: 2.6em; /* Fallback for non-webkit, line-height * 2 */
    line-height: 1.3em;
    -webkit-line-clamp: 2; /* if you change this, make sure to change the fallback line-height and height */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 225px;
  }

  .noti-dot {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: #2e89ff;
  }

  .bg-light-primary {
    background-color: rgb(13 110 253 / 0.11) !important;
  }
  .text-primary {
    color: #008cff !important;
  }
`;

const NotiSvg = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;

  svg {
    height: 112px;
  }

  h3 {
    font-weight: 700;
  }
`;

export {
  HeaderContainer,
  NavbarContainer,
  HeaderSearch,
  HeaderSearchBarBox,
  FormControl,
  IconSearchShow,
  MobileToggleMenu,
  TopMenu,
  NavbarList,
  NavbarItem,
  NavbarLink,
  AlertCount,
  UserBox,
  UserImg,
  UserInfo,
  MsgContainer,
  MsgHeader,
  NotiContainer,
  NotiSvg,
};
