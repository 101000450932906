import { AxiosResponse } from "axios";
import { axiosInstance } from "../../config/axios.config";

type actionType = {
  type: string;
  _payload: any;
};

const roleApi = {
  getAllRole: async ({
    _payload,
  }: actionType): Promise<AxiosResponse<any, any>> => {
    const url = "role";
    const response = (await axiosInstance.get)<any>(url);
    return response;
  },
};

export { roleApi };
