import { call, put, takeEvery } from "redux-saga/effects";
import accountApi from "./accountApi";
import { accountActions } from "./accountSlice";

type ActionType = {
  type: string;
  payload: any;
};

export function* getAccountWorker({ payload }: ActionType): Generator {
  try {
    const response = yield call(accountApi.getListAccount, payload);

    yield put(accountActions.getAccountSuccess(response));
    // TODO: handle callback | onSuccess
  } catch (error) {
    yield put(accountActions.getAccountFailure());
  }
}

export function* getEmployeeIdCreatedWorker({
  payload,
}: ActionType): Generator {
  try {
    const response = yield call(accountApi.getListAccount, payload);
    yield put(accountActions.getEmployeeIdCreatedSuccess(response));
    // TODO: handle callback | onSuccess
  } catch (error) {
    yield put(accountActions.getEmployeeIdCreatedFailure());
  }
}

export function* lockUser({ payload }: ActionType): Generator {
  try {
    yield call(accountApi.lockUser, payload);
    yield put(accountActions.lockUserSuccess());
  } catch (error) {
    yield put(accountActions.lockUserFailure());
  }
}

/*Watcher*/
export const accountWatcher = [
  takeEvery(accountActions.getAccount, getAccountWorker),
  takeEvery(accountActions.getEmployeeIdCreated, getEmployeeIdCreatedWorker),
  takeEvery(accountActions.lockUser, lockUser),
];
