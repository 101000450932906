import { AxiosResponse } from "axios";
import {
  AddNewPasswordType,
  CheckPhoneType,
  LoginType,
  UpdatePhoneNumber,
  UpdateEmailAddress,
  ResetPasswordType,
  ResendEmailType,
} from "./authSaga";
import { VIETNAM_COUNTRY_CODE } from "../../utils/constants";
import { axiosInstance } from "../../config/axios.config";

type LoginData = {
  token: string;
  expiration: string;
};

type LoginResponse = {
  data: LoginData;
  isSucceeded: boolean;
};
type ChangPasswordResponse = {
  isSucceeded: boolean;
};
type CheckPasswordResponse = {
  isSucceeded: boolean;
};
type ResetPasswordResponse = {
  isSucceeded: boolean;
};
type GetGoogleTokenResponse = {
  data: any;
  errors: any;
  isSucceeded: boolean;
};

const authApi = {
  login: async ({
    payload,
  }: LoginType): Promise<AxiosResponse<LoginResponse, any>> => {
    const url = "Login";
    const response = (await axiosInstance.post)<LoginResponse>(
      url,
      payload.values
    );
    return response;
  },
  googleToken: async (): Promise<
    AxiosResponse<GetGoogleTokenResponse, any>
  > => {
    const url = "UploadFile/get-access-google-token";
    const response = (await axiosInstance.post)<any>(url);
    return response;
  },
  addNewPassword: async ({
    payload,
  }: AddNewPasswordType): Promise<AxiosResponse<ChangPasswordResponse>> => {
    const url = "User/add-new-password";
    const config = {
      headers: { Authorization: `Bearer ${payload.values.accessToken}` },
    };
    const response = await axiosInstance.post(
      url,
      {
        newPassword: payload.values.newPassword,
        confirmNewPassword: payload.values.confirmNewPassword,
        phoneNumber: VIETNAM_COUNTRY_CODE + payload.values.phoneNumber,
      },
      config
    );
    return response;
  },
  checkPhoneNumber: async ({
    payload,
  }: CheckPhoneType): Promise<AxiosResponse<CheckPasswordResponse>> => {
    const url = "User/CheckUserAdmin";
    const response = await axiosInstance.post(url, payload);
    return response;
  },
  updatePhoneNumber: async ({
    payload,
  }: UpdatePhoneNumber): Promise<AxiosResponse<any, any>> => {
    const url = "User/update-phone-number";
    const response = await axiosInstance.post(url, payload);
    return response;
  },
  updateEmailAddress: async ({
    payload,
  }: UpdateEmailAddress): Promise<AxiosResponse<any, any>> => {
    const url = "User/update-email";
    const response = await axiosInstance.post(url, payload);
    return response;
  },
  resetPassword: async ({
    payload,
  }: ResetPasswordType): Promise<AxiosResponse<ResetPasswordResponse>> => {
    const url = "User/request-reset-password";
    const response = await axiosInstance.post(url, payload);
    return response;
  },
  resendEmail: async ({
    payload,
  }: ResendEmailType): Promise<AxiosResponse<any>> => {
    const url = "User/resend-email";
    const response = await axiosInstance.post(url, payload);
    return response;
  },
};

export { authApi };
