import { call, put, takeEvery } from "redux-saga/effects";
import { todoTaskApi } from "./todoTaskApi";
import { todoTaskActions } from "./todoTaskSlice";

export function* getAllWorker(): Generator {
  try {
    const response = yield call(todoTaskApi.getAll);
    yield put(todoTaskActions.getAllSuccess(response));
    // TODO: handle callback | onSuccess
  } catch (error) {
    yield put(todoTaskActions.getAllFailure());
    // TODO: handle callback | onFailure
  }
}

/*Watcher*/
export const todoTaskWatcher = [
  takeEvery(todoTaskActions.getAllRequest, getAllWorker),
];
