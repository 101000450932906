import { call, put, takeEvery } from "redux-saga/effects";
import { eventApi } from "./eventApi";
import { eventActions } from "./eventSlice";

type ActionType = {
  type: string;
  payload: any;
};

export function* getAllEventWorker({ payload }: ActionType): Generator {
  try {
    const response = yield call(eventApi.getAllEvent, payload);
    yield put(eventActions.getAllEventSuccess(response));
    // TODO: handle callback | onSuccess
  } catch (error) {
    yield put(eventActions.getAllEventFailure());
    // TODO: handle callback | onFailure
  }
}

export function* getNewestEventWorker({ payload }: ActionType): Generator {
  try {
    const response = yield call(eventApi.getNewestEvents, payload);
    yield put(eventActions.getNewestEventSuccess(response));
    // TODO: handle callback | onSuccess
    if (payload.hasOwnProperty("onSuccess")) {
      yield call(payload.onSuccess);
    }
  } catch (error) {
    yield put(eventActions.getNewestEventFailure());
    // TODO: handle callback | onFailure
    if (payload.hasOwnProperty("onError")) {
      yield call(payload.onError);
    }
  }
}

export function* getEventByIdWorker({ payload }: ActionType): Generator {
  try {
    const response = yield call(eventApi.getEventById, payload);
    yield put(eventActions.getEventByIdSuccess(response));
    // TODO: handle callback | onSuccess
    if ((response as any).isSucceeded && payload.hasOwnProperty("onSuccess")) {
      yield call(payload.onSuccess);
    }
  } catch (error) {
    yield put(eventActions.getEventByIdFailure());
    // TODO: handle callback | onFailure
    if (payload.hasOwnProperty("onError")) {
      yield call(payload.onError);
    }
  }
}

export function* createEventWorker({ payload }: ActionType): Generator {
  try {
    const response = yield call(eventApi.createEvent, payload);
    yield put(eventActions.createEventSuccess(response));
    // TODO: handle callback | onSuccess
    if ((response as any).isSucceeded && payload.hasOwnProperty("onSuccess")) {
      yield call(payload.onSuccess);
    }
  } catch (error) {
    yield put(eventActions.createEventFailure());
    // TODO: handle callback | onError
    if (payload.hasOwnProperty("onError")) {
      yield call(payload.onError);
    }
  }
}

export function* updateEventWorker({ payload }: ActionType): Generator {
  try {
    const response = yield call(eventApi.updateEvent, payload);
    yield put(eventActions.updateEventSuccess(response));
    // TODO: handle callback | onSuccess
    if ((response as any).isSucceeded && payload.hasOwnProperty("onSuccess")) {
      yield call(payload.onSuccess);
    }
  } catch (error) {
    yield put(eventActions.updateEventFailure());
    // TODO: handle callback | onError
    if (payload.hasOwnProperty("onError")) {
      yield call(payload.onError);
    }
  }
}

export function* deleteEventWorker({ payload }: ActionType): Generator {
  try {
    const response = yield call(eventApi.deleteEvent, payload);
    yield put(eventActions.deleteEventSuccess(response));
    // TODO: handle callback | onSuccess
    if (payload.hasOwnProperty("onSuccess")) {
      yield call(payload.onSuccess);
    }
  } catch (error) {
    yield put(eventActions.deleteEventFailure());
    // TODO: handle callback | onError
    if (payload.hasOwnProperty("onError")) {
      yield call(payload.onError);
    }
  }
}

/*Watcher*/
export const eventWatcher = [
  takeEvery(eventActions.getAllEventRequest, getAllEventWorker),
  takeEvery(eventActions.getNewestEventRequest, getNewestEventWorker),
  takeEvery(eventActions.getEventByIdRequest, getEventByIdWorker),
  takeEvery(eventActions.createEventRequest, createEventWorker),
  takeEvery(eventActions.updateEventRequest, updateEventWorker),
  takeEvery(eventActions.deleteEventRequest, deleteEventWorker),
];
