import { useAppDispatch, useAppSelector } from "@config/hooks";
import { employeeActions } from "@features/employee/employeeSlice";
import { userActions } from "@features/user/userSlice";
import { checkTokenExpire, getWelcomePopupStatus } from "@utils/helpers";
import { Modal } from "antd";
import moment from "moment";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FooterAdmin, HeaderAdmin, Sidebar } from "./components";
import LayoutAdminWrapper from "./LayoutAdminStyled";
import { CheckExistRole } from "@utils/auth";
import { EmployeeRole } from "@utils/constants";

interface LayoutAdminProps {
  children: React.ReactNode;
}

const LayoutAdmin = ({ children }: LayoutAdminProps) => {
  const [collapsed, setCollapsed] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const user = useAppSelector((state) => state.user.user);
  const employeeOnboardComing = useAppSelector(
    (state) => state.employee.getEmployeeOnboardComingResponse
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isWelcomeShowed = useRef(false);

  const handleCloseSideBar = () => {
    if (window.innerWidth <= 900 && collapsed === false) {
      setCollapsed(true);
    }
  };

  useLayoutEffect(() => {
    handleCloseSideBar();
  }, []);

  useEffect(() => {
    if (!CheckExistRole(user, EmployeeRole))
      if (!employeeOnboardComing) {
        dispatch(employeeActions.getEmployeeOnboardComingRequest({}));
      }
  }, []);

  useEffect(() => {
    if (CheckExistRole(user, EmployeeRole)) {
      setCollapsed(true);
    }
  }, [user?.role]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    dispatch(
      userActions.logout({
        onSuccess: () => {
          navigate("/");
        },
      })
    );
  };

  useEffect(() => {
    if (checkTokenExpire()) {
      showModal();
    }
  }, [checkTokenExpire()]);

  useEffect(() => {
    const welcomePopupStatus = getWelcomePopupStatus();
    const isShowed = welcomePopupStatus
      ? moment(welcomePopupStatus.lastDate).isSame(moment(), "date") ||
        welcomePopupStatus.role !== user?.role
      : isWelcomeShowed.current;
    if (user && !isShowed && employeeOnboardComing) {
      isWelcomeShowed.current = true;
      Modal.destroyAll();
      // setTimeout(() => {
      //   Modal.info({
      //     title: "Onboard information",
      //     content: (
      //       <OnboardListContainer>
      //         {employeeOnboardComing?.map((employee: IEmployeeOnboard) => (
      //           <div className="employee">
      //             <img className="avatar" src={employee.avatar} alt="avatar" />
      //             <div className="info">
      //               <p className="fullname">
      //                 {employee.fullName} ({employee.account})
      //               </p>
      //               <p className="date">
      //                 {moment(employee.onboardDate).format(DATE_FORMAT)}
      //               </p>
      //             </div>
      //           </div>
      //         ))}
      //       </OnboardListContainer>
      //     ),
      //     centered: true,
      //     afterClose: () => {
      //       setWelcomePopupStatus(moment(), user.role as string);
      //     },
      //   });
      // }, 1000);
    }
  }, [user, employeeOnboardComing]);

  return (
    <LayoutAdminWrapper collapsed={collapsed}>
      <div className="header">
        <HeaderAdmin setCollapsed={setCollapsed} collapsed={collapsed} />
      </div>
      <Modal
        title="Session Timeout"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleOk}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ danger: true }}
      >
        <p>The session has expired. Please login again to continue using.</p>
      </Modal>
      <div className="main-content">
        {!CheckExistRole(user, EmployeeRole) ? (
          <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
        ) : null}
        <div className="content" onClick={handleCloseSideBar}>
          <div className="outlet-content">
            {children}
            {/* <Outlet /> */}
          </div>
          <div className="footer">
            <FooterAdmin />
          </div>
        </div>
      </div>
    </LayoutAdminWrapper>
  );
};

export default LayoutAdmin;
