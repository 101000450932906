import { axiosInstance } from "@config/axios.config";
import { AxiosResponse } from "axios";

const certificatesApi = {
  getAllCert: async (payload: any): Promise<AxiosResponse<any, any>> => {
    const url = `Certificate/FilterEmployee`;
    const response: AxiosResponse = await axiosInstance.get<any>(url, {
      params: payload,
    });
    return response;
  },
};

export { certificatesApi };
