import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  customerLoading: false,
  createCustomerLoading: false,
  editCustomerLoading: false,
  getAllCustomerResponse: null,
  getCustomerByIdResponse: null,
};

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    getAllCustomerRequest(state,_payload) {
      state.customerLoading = true;
    },
    getAllCustomerSuccess(state, { payload }) {
      state.getAllCustomerResponse = payload.data;
      state.customerLoading = false;
    },
    getAllCustomerFailure(state) {
      state.customerLoading = false;
    },
    getCustomerByIdRequest(state, _payload) {
      state.customerLoading = true;
    },
    getCustomerByIdSuccess(state, { payload }) {
      state.getCustomerByIdResponse = payload.data;
      state.customerLoading = false;
    },
    getCustomerByIdFailure(state) {
      state.customerLoading = false;
    },
    createCustomerRequest(state, _payload) {
      state.createCustomerLoading = true;
    },
    createCustomerSuccess(state, _payload) {
      state.createCustomerLoading = false;
    },
    createCustomerFailure(state) {
      state.createCustomerLoading = false;
    },
    deleteCustomerRequest(state, _payload) {
      state.customerLoading = true;
    },
    deleteCustomerSuccess(state) {
      state.customerLoading = false;
    },
    deleteCustomerFailure(state) {
      state.customerLoading = false;
    },
    editCustomerRequest(state, _payload) {
      state.editCustomerLoading = true;
    },
    editCustomerSuccess(state, _payload) {
      state.editCustomerLoading = false;
    },
    editCustomerFailure(state) {
      state.editCustomerLoading = false;
    },
  },
});

const { actions, reducer } = customerSlice;
export { actions as customerActions, reducer as customerReducer };
