import { AxiosResponse } from "axios";
import { axiosInstance } from "../../config/axios.config";
import {
  IAccount,
  IAccountSearch,
  IAccountUpdate,
} from "@pages/UserPermission/userPermissionType";

type BaseResponse = any;

// type BaseResponse = {
//   data: any;
//   errors: any;
//   isSucceeded: boolean;
// };

const accountApi = {
  createNewAccount: async (
    accountDataPost: IAccount
  ): Promise<AxiosResponse<BaseResponse, any>> => {
    const url = "Auth/Create-Account";
    const response = (await axiosInstance.post)(url, accountDataPost);

    return response;
  },
  getListAccount: async (
    accountDataSearch: IAccountSearch
  ): Promise<AxiosResponse<BaseResponse, any>> => {
    const url = "User/get-list-user";
    const response = (await axiosInstance.post)(url, accountDataSearch);

    return response;
  },
  updateAccount: async (
    accountDataUpdate: IAccountUpdate
  ): Promise<AxiosResponse<BaseResponse, any>> => {
    const url = "User/update-line-role-permission";
    const response = (await axiosInstance.put)(url, accountDataUpdate);

    return response;
  },
  deleteAccount: async (
    idAccDelete: string
  ): Promise<AxiosResponse<BaseResponse, any>> => {
    const url = `Auth/Delete-Account/${idAccDelete}`;
    const response = (await axiosInstance.delete)(url);

    return response;
  },
  lockUser: async (
    accountId: string
  ): Promise<AxiosResponse<BaseResponse, any>> => {
    const url = `User/lock-user?userId=${accountId}`;
    const response = (await axiosInstance.post)(url);

    return response;
  },
};

export default accountApi;
