import { showErrorNotification } from "@utils/notification";
import { call, put, takeEvery } from "redux-saga/effects";
import { uploadApi } from "./uploadApi";
import { uploadActions } from "./uploadSlice";

type ActionType = {
  type: string;
  payload: any;
};

export function* importEmployeeWorker({ payload }: ActionType): Generator {
  try {
    yield call(uploadApi.importEmployee, payload);
    yield put(uploadActions.importEmployeeSuccess());
    // TODO: handle callback | onSuccess
  } catch (error) {
    showErrorNotification("Import employee failure!");
    // yield put(uploadActions.failure(error));
    // TODO: handle callback | onFailure
  }
}

export function* uploadImageWorker({ payload }: ActionType): Generator {
  try {
    const response = yield call(uploadApi.uploadImage, payload);
    if ((response as any).isSucceeded && payload.hasOwnProperty("onSuccess")) {
      yield call(payload.onSuccess);
    }
    yield put(uploadActions.uploadImageSuccess(response));
  } catch (error) {
    if (payload.hasOwnProperty("onError")) {
      yield call(payload.onError);
    }
  }
}

/*Watcher*/
export const uploadWatcher = [
  takeEvery(uploadActions.importEmployeeRequest, importEmployeeWorker),
  takeEvery(uploadActions.uploadImageRequest, uploadImageWorker),
];
