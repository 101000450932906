import { createSlice } from "@reduxjs/toolkit";

type stateType = {
  getAllUserLoading: boolean;
  getAllUserResponse: any;
  getParam: any;
  sendMessageLoading: boolean;

  getAllHistoryMessageLoading: boolean;
  getAllHistoryMessageResponse: any;

  getCurrentUserLoading: boolean;
  getCurrentUserResponse: any;
};

const initialState: stateType = {
  getAllUserLoading: false,
  getAllUserResponse: null,
  getParam: null,

  sendMessageLoading: false,

  getAllHistoryMessageLoading: false,
  getAllHistoryMessageResponse: null,

  getCurrentUserLoading: false,
  getCurrentUserResponse: null,
};

const feedbackSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {
    //Get all User
    getAllUserRequest(state) {
      state.getAllUserLoading = true;
      return state;
    },
    getAllUserSuccess(state, { payload }) {
      state.getAllUserResponse = payload.data;
      state.getAllUserLoading = false;
    },
    getAllUserFailure(state) {
      state.getAllUserLoading = false;
    },
    //send Message
    sendMessageRequest(state, _payload) {
      state.sendMessageLoading = true;
    },
    sendMessSuccess(state) {
      state.sendMessageLoading = false;
    },
    sendMessFailure(state) {
      state.sendMessageLoading = false;
    },
    //Get all message
    getAllMessageRequest(state, _payload) {
      state.getAllHistoryMessageLoading = true;
    },
    getAllMessageSuccess(state, { payload }) {
      state.getAllHistoryMessageLoading = false;
      state.getAllHistoryMessageResponse = payload.data;
    },
    getAllMessageFailure(state) {
      state.getAllHistoryMessageLoading = false;
    },
  },
});

const { actions, reducer } = feedbackSlice;

export { actions as feedbackAction, reducer as feedbackReducer };
