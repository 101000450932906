import { Footer } from "antd/lib/layout/layout";
import styled from "styled-components";

export const FooterAdmin = styled(Footer)`
  &&& {
    background-color: #111010;
    color: #fff;
    font-size: 0.875rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .copy-right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 1rem;
    }

    .logo img {
      height: 60px;
    }
  }
`;
