import { createSlice } from "@reduxjs/toolkit";
import { LoginResponse } from "../../pages/LoginPage/LoginPage";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../utils/notification";

type stateType = {
  loginResponse: LoginResponse | null;
  loginSuccess: null | boolean;
  loginLoading: boolean;
  logoutLoading: boolean;
  addNewPasswordLoading: boolean;
  checkPhoneNumberLoading: boolean;
  updatePhoneNumberLoading: boolean;
  googleTokenResponse: null;
  updateEmailAddressLoading: boolean;
  resetPasswordLoading: boolean;
  resendEmailLoading: boolean;
};

const initialState: stateType = {
  loginResponse: null,
  loginSuccess: null,
  loginLoading: false,
  logoutLoading: false,
  addNewPasswordLoading: false,
  checkPhoneNumberLoading: false,
  updatePhoneNumberLoading: false,
  googleTokenResponse: null,
  updateEmailAddressLoading: false,
  resetPasswordLoading: false,
  resendEmailLoading: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(state, _action) {
      state.loginLoading = true;
      /* TODO: reducer for pending*/
    },
    loginSuccess(state, action) {
      /* TODO: reducer for success*/
      const loginResponse = action.payload;
      localStorage.setItem("token", loginResponse.token);
      localStorage.setItem("expiration", loginResponse.expiration);
      localStorage.setItem("refreshToken", loginResponse.refresh);
      // document.cookie =
      //   "token=" +
      //   loginResponse.token +
      //   "; expiration:" +
      //   loginResponse.expiration +
      //   "; max-age=30";
      state.loginResponse = loginResponse;
      state.loginSuccess = true;
      state.loginLoading = false;
    },
    loginFailure(state, action) {
      if (action.payload.response?.data?.errors) {
        showErrorNotification(action.payload.response.data.errors[0].code);
      }
      state.loginSuccess = false;
      state.loginLoading = false;
      /* TODO: reducer for failure*/
    },
    // logout(state) {
    //   state.logoutLoading = true;
    //   // state.loginResponse = null;
    //   // localStorage.removeItem("token");
    //   // localStorage.removeItem("expiration");
    // },
    // logoutSuccess(state) {
    //   state.loginResponse = null;
    //   localStorage.removeItem("token");
    //   localStorage.removeItem("expiration");
    //   state.logoutLoading = false;
    // },
    // logoutFailure(state) {
    //   state.logoutLoading = false;
    // },

    checkPhoneNumber(state, _action) {
      state.checkPhoneNumberLoading = true;
    },
    checkPhoneNumberSuccess(state) {
      state.checkPhoneNumberLoading = false;
    },
    checkPhoneNumberFailure(state) {
      state.checkPhoneNumberLoading = false;
    },
    addNewPassword(state, _action) {
      state.addNewPasswordLoading = true;
    },
    addNewPasswordSuccess(state) {
      state.addNewPasswordLoading = false;
      showSuccessNotification("Change password successful!");
    },
    addNewPasswordFailure(state) {
      state.addNewPasswordLoading = false;
      showErrorNotification("Change password failed!");
    },
    updatePhoneNumber(state, _action) {
      state.updatePhoneNumberLoading = true;
    },
    updatePhoneNumberSuccess(state) {
      state.updatePhoneNumberLoading = false;
      showSuccessNotification("Update phone number successful!");
    },
    updatePhoneNumberFailure(state) {
      state.updatePhoneNumberLoading = false;
      showErrorNotification("Update phone number failed!");
    },
    getGoogleToken(_state) {},
    getGoogleTokenSuccess(state, action) {
      state.loginSuccess = true;
      state.googleTokenResponse = action.payload;
    },
    getGoogleTokenFailure(state, action) {
      showErrorNotification(action.payload.response.data.errors[0].code);
      state.loginSuccess = false;
    },
    updateEmailAddress(state, _action) {
      state.updateEmailAddressLoading = true;
    },
    updateEmailAddressSuccess(state) {
      state.updateEmailAddressLoading = false;
      showSuccessNotification("Update email successful!");
    },
    updateEmailAddressFailure(state) {
      state.updateEmailAddressLoading = false;
      showErrorNotification("Update email failed!");
    },

    resetPassword(state, _action) {
      state.resetPasswordLoading = true;
    },
    resetPasswordSuccess(state) {
      state.resetPasswordLoading = false;
      showSuccessNotification("Reset password successful!");
    },
    resetPasswordFailure(state, action) {
      state.resetPasswordLoading = false;
      if (action.payload.response?.data?.errors) {
        showErrorNotification(action.payload.response.data.errors[0].code);
      }
    },
    resendEmail(state, _action) {
      state.resendEmailLoading = true;
    },
    resendEmailSuccess(state) {
      state.resendEmailLoading = false;
      showSuccessNotification("Resend email successful!");
    },
    resendEmailFailure(state) {
      state.resendEmailLoading = false;
      showErrorNotification("Resend email failed!");
    },
  },
});

const { actions, reducer } = authSlice;

export { actions as authActions, reducer as authReducer };
