import { call, put, takeEvery } from "redux-saga/effects";
import { lineApi } from "./lineApi";
import { lineActions } from "./lineSlice";

type ActionType = {
  type: string;
  payload: any;
};

export function* getAllLineWorker({ payload }: ActionType): Generator {
  try {
    const response = yield call(lineApi.getAllLine, payload);
    yield put(lineActions.getAllLineSuccess(response));
    // TODO: handle callback | onSuccess
  } catch (error) {
    yield put(lineActions.getAllLineFailure());
    // TODO: handle callback | onFailure
  }
}

/*Watcher*/
export const lineWatcher = [
  takeEvery(lineActions.getAllLineRequest, getAllLineWorker),
];
