import { createSlice } from "@reduxjs/toolkit";
import { IPayloadAction } from "models/base.model";
import {
  IGetEEReportRequest,
  IGetEEReportResponse,
  IEEState,
} from "./ee.model";

const initialState: IEEState = {
  getEEResponse: null,
  getEELoading: false,
};

const eeSlice = createSlice({
  name: "ee",
  initialState,
  reducers: {
    calculateEERequest(
      state,
      _action: IPayloadAction<IGetEEReportRequest>
    ) {
      state.getEELoading = true;
    },
    getEEReportSuccess(
      state,
      action: IPayloadAction<IGetEEReportResponse>
    ) {
      state.getEEResponse = action.payload.data;
      state.getEELoading = false;
    },
    getEEReportFailure(state) {
      state.getEELoading = false;
    },
  },
});

const { actions, reducer } = eeSlice;

export {
  actions as eeActions,
  reducer as eeReducer,
};
