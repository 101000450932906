import React from "react";
import { Link } from "react-router-dom";
import { LogoWhite } from "@utils/media";
import { FooterAdmin } from "./FooterStyled";

const Footer = () => {
  return (
    <FooterAdmin>
      <Link to="/" className="logo">
        <img src={LogoWhite} alt="logo" />
      </Link>

      <div className="footer-text">Copyright © {new Date().getFullYear()}</div>
    </FooterAdmin>
  );
};

export default Footer;
