import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface IWorkDiaryState {
  employee: IEmployee[];
  isCreate: boolean;
  meetingNoteLoading: boolean;
  employeeLoading: boolean;
  getAllEmployeeResponse: any;
  getMeetingNoteResponse: any;
  getListEmployeeResponse: any;
}

interface IEmployeeResponse {
  id: string;
  userName: string;
  email: string;
  phoneNumber: string;
}

interface IEmployee {
  value: string;
  label: string;
}

const initialState: IWorkDiaryState = {
  employee: [],
  isCreate: false,
  meetingNoteLoading: false,
  employeeLoading: false,
  getAllEmployeeResponse: null,
  getMeetingNoteResponse: null,
  getListEmployeeResponse: null,
};

export const workDiarySlice = createSlice({
  name: "workDiary",
  initialState,
  reducers: {
    fetchAllEmployee: () => {},
    fetchAllEmployeeSucceeded: (
      state,
      action: PayloadAction<Record<string, any>>
    ) => {
      state.employee = action.payload.data.map((item: any) => ({
        value: item.id,
        label: item.account,
      }));
    },
    fetchAllEmployeeFailed: () => {
      throw new Error("Fetch data failed");
    },
    createWorkDiary: (state, _action) => {
      state.meetingNoteLoading = true;
    },
    createWorkDiarySucceeded: (state, _action) => {
      state.meetingNoteLoading = false;
    },
    createWorkDiaryFailed: (state) => {
      state.meetingNoteLoading = false;
    },
    getAllMeetingNote(state, _action) {
      state.meetingNoteLoading = true;
    },
    getAllMeetingNoteSuccess(state, { payload }) {
      state.getAllEmployeeResponse = payload.data;
      state.meetingNoteLoading = false;
    },
    getAllMeetingNoteFailure(state) {
      state.meetingNoteLoading = false;
    },
    deleteMeetingNote(state, _action) {
      state.meetingNoteLoading = true;
    },
    deleteMeetingNoteSuccess(state) {
      state.meetingNoteLoading = false;
    },
    deleteMeetingNoteFailure(state) {
      state.meetingNoteLoading = false;
    },
    getMeetingNote(state, _action) {
      state.meetingNoteLoading = true;
    },
    getMeetingNoteSuccess(state, { payload }) {
      state.getMeetingNoteResponse = payload.data;
      state.meetingNoteLoading = false;
    },
    getMeetingNoteFailure(state) {
      state.meetingNoteLoading = false;
    },
    editMeetingNote(state, _action) {
      state.meetingNoteLoading = true;
    },
    editMeetingNoteSuccess(state, { payload }) {
      state.getMeetingNoteResponse = payload.data;
      state.meetingNoteLoading = false;
    },
    editMeetingNoteFailure(state) {
      state.meetingNoteLoading = false;
    },
    getListEmployee(state) {
      state.employeeLoading = true;
    },
    getListEmployeeSuccess(state, { payload }) {
      state.getListEmployeeResponse = payload.data.map(
        (item: IEmployeeResponse) => ({
          value: item.id,
          label: item.userName,
        })
      );
      state.employeeLoading = false;
    },
    getListEmployeeFailure(state) {
      state.employeeLoading = false;
    },
  },
});

const { actions, reducer } = workDiarySlice;
export { actions as workDiaryActions, reducer as workDiaryReducer };
