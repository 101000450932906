import { createSlice } from "@reduxjs/toolkit";
import { IPayloadAction } from "models/base.model";
import {
  IGetAllResourceAllocationRequest,
  IGetAllResourceAllocationResponse,
  IResourceAllocationState,
} from "./resourceAllocation.model";
import { generateUUID } from "@utils/helper/string.helper";

const initialState: IResourceAllocationState = {
  getAllResourceAllocationResponse: null,
  getAllResourceAllocationLoading: false,
};

const resourceAllocationSlice = createSlice({
  name: "resourceAllocation",
  initialState,
  reducers: {
    getAllResourceAllocationRequest(
      state,
      _action: IPayloadAction<IGetAllResourceAllocationRequest>
    ) {
      state.getAllResourceAllocationLoading = true;
    },
    getAllResourceAllocationSuccess(
      state,
      action: IPayloadAction<IGetAllResourceAllocationResponse>
    ) {
      // add unique key for datasource to show on table
      action.payload.data.allocatedResources =
        action.payload.data?.allocatedResources?.map((item) => {
          return {
            ...item,
            key: generateUUID(), // generate key
          };
        });
      action.payload.data.notYetAllocatedResources =
        action.payload.data?.notYetAllocatedResources?.map((item) => {
          return {
            ...item,
            key: generateUUID(), // generate key
          };
        });

      action.payload.data.forecastIdleResources =
        action.payload.data?.forecastIdleResources?.map((item) => {
          return {
            ...item,
            key: generateUUID(), // generate key
          };
        });

      state.getAllResourceAllocationResponse = action.payload.data;
      state.getAllResourceAllocationLoading = false;
    },
    getAllResourceAllocationFailure(state) {
      state.getAllResourceAllocationLoading = false;
    },
    changeResources(state, action: any) {
      state.getAllResourceAllocationResponse = action.payload;
    },
  },
});

const { actions, reducer } = resourceAllocationSlice;

export {
  actions as resourceAllocationActions,
  reducer as resourceAllocationReducer,
};
