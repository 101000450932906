import { AxiosResponse } from "axios";
import { axiosInstance } from "../../config/axios.config";

const todoTaskApi = {
  getAll: async (): Promise<AxiosResponse<any, any>> => {
    const url = "TodoTask";
    const response = (await axiosInstance.get)<any>(url);
    return response;
  },
};

export { todoTaskApi };
