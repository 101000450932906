import { API_ENDPOINT } from "@config/apiEndpoint.config";
import { AxiosResponse } from "axios";
import { axiosInstance } from "@config/axios.config";
import {
  IGetAllResourceAllocationRequest,
  IGetAllResourceAllocationResponse,
} from "./resourceAllocation.model";
import { IBaseResponse } from "models/base.model";

const resourceAllocationService = {
  getAllResourceAllocation: async (
    data: IGetAllResourceAllocationRequest
  ): Promise<
    AxiosResponse<IBaseResponse<IGetAllResourceAllocationResponse>>
  > => {
    const url = API_ENDPOINT.RESOURCE_ALLOCATION.GET_RESOURCE_ALLOCATION;
    const response = await axiosInstance.post(url, data);
    return response;
  },
};

export { resourceAllocationService };
