import { lazy } from "react";
import { Navigate } from "react-router-dom";
import { ROUTE_NAMES } from "@utils/constants/routeNames.constant";

const { DEVICE } = ROUTE_NAMES;

export const adminRoutes = [
  {
    id: "home",
    path: "/",
    component: lazy(() => import("../pages/HomePage")),
  },
  {
    id: "employees",
    path: "/employees",
    component: lazy(() => import("../pages/EmployeesPage")),
  },
  {
    id: "employeeDetail",
    path: "/employees/:id",
    component: lazy(() => import("../pages/EmployeeDetailPage")),
  },
  {
    id: "projects",
    path: "/projects",
    component: lazy(() => import("../pages/SamplePage")),
  },
  {
    id: "create-onboard",
    path: "/admin/create-onboard",
    component: lazy(
      () => import("../pages/AdminSitePage/components/CreateOnboard")
    ),
  },
  {
    id: "list-onboard",
    path: "/admin/list-onboard",
    component: lazy(
      () => import("../pages/AdminSitePage/components/ListOnboard")
    ),
  },
  {
    id: "list-employee",
    path: "/admin/list-employee",
    component: lazy(
      () => import("../pages/AdminSitePage/components/ListEmployee")
    ),
  },
  {
    id: "edit-onboard",
    path: "/admin/edit-onboard/:id",
    component: lazy(
      () => import("../pages/AdminSitePage/components/EditOnboard")
    ),
  },
  {
    id: "event-details",
    path: "/events/create",
    component: lazy(() => import("../pages/CreateEventPage")),
  },
  {
    id: "event-details",
    path: "/events/edit/:id",
    component: lazy(() => import("../pages/EditEventPage")),
  },
  {
    id: "event-details",
    path: "/events/:id",
    component: lazy(() => import("../pages/EventDetailsPage")),
  },
  {
    id: "events",
    path: "/events",
    component: lazy(() => import("../pages/EventsPage")),
  },
  {
    id: "profile",
    path: "/profile",
    component: lazy(() => import("../pages/Profile")),
  },
  {
    id: "history",
    path: "/history-action",
    component: lazy(() => import("../pages/HistoryPage")),
  },
  {
    id: "feedback",
    path: "/feedback",
    component: lazy(() => import("../pages/FeedbackPage")),
  },
  {
    id: "setting",
    path: "/setting",
    component: lazy(() => import("../pages/Setting")),
  },
  {
    id: "userPermission",
    path: "/user-permission",
    component: lazy(() => import("../pages/UserPermission")),
  },
  {
    id: "logEmail",
    path: "/log-email",
    component: lazy(() => import("../pages/LogEmail")),
  },
  {
    id: "planning",
    path: "/planning",
    component: lazy(() => import("../pages/ResourcePlanning/ResourcePlanning")),
  },
  {
    id: "allocation",
    path: "/allocation",
    component: lazy(() => import("../pages/allocation/AllocationPage")),
  },
  {
    id: "ee",
    path: "/ee",
    component: lazy(() => import("../pages/EE/EE")),
  },
  {
    id: "odcallowance",
    path: "/odcallowance",
    component: lazy(() => import("../pages/OdcAllowance")),
  },
  {
    id: "customer",
    path: "/customer",
    component: lazy(() => import("../pages/CustomerPage/CustomerPage")),
  },
  {
    id: "work-diary",
    path: "/work-diary",
    component: lazy(() => import("../pages/WorkDiary")),
  },
  {
    id: "certificates-management",
    path: "/certificates",
    component: lazy(
      () => import("../pages/CertificatesManagement/CertificatesPage")
    ),
  },
  {
    id: DEVICE.ID,
    path: DEVICE.URL,
    component: lazy(
      () => import("../pages/DeviceManagement/DeviceManagementPage")
    ),
  },
  {
    id: "todo-task",
    path: "/todo-task",
    component: lazy(() => import("../pages/TodoTaskPage")),
  },
  {
    id: "kpi",
    path: "/kpi-page",
    component: lazy(() => import("../pages/KpiPage")),
  },
  {
    id: "project-plan",
    path: "/project-plan",
    component: lazy(() => import("../pages/ProjectPlan/ProjectPlanPage")),
  },
  {
    id: "no-authorized",
    path: "/no-authorized",
    component: lazy(() => import("../pages/NoAuthorizedPage")),
  },
  {
    id: "private-routes",
    path: "*",
    component: () => <Navigate to="/" />,
  },
];

export const publicRoutes = [
  {
    id: "login",
    path: "/login",
    component: lazy(() => import("../pages/LoginPage")),
  },
  {
    id: "forgotPassword",
    path: "/forgotPassword",
    component: lazy(() => import("../pages/ForgotPasswordPage")),
  },
  {
    id: "resetPassword",
    path: "/resetPassword",
    component: lazy(() => import("../pages/ResetPasswordPage")),
  },
  {
    id: "public-routes",
    path: "*",
    component: () => <Navigate to="/login" />,
  },
];
