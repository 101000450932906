import { AxiosResponse } from "axios";
import { IPayloadAction } from "models/base.model";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  IExportOdcAllowanceReport,
  IExportOdcAllowanceReportRequest,
} from "./odcAllowance.model";
import { odcAllowanceService } from "./odcAllowance.service";
import { odcAllowanceActions } from "./odcAllowance.slice";

// Worker
export function* exportOdcAllowanceReportWorker({
  payload,
}: IPayloadAction<IExportOdcAllowanceReportRequest>) {
  try {
    const response: AxiosResponse<IExportOdcAllowanceReport> = yield call(
      odcAllowanceService.exportReport,
      payload.data
    );
    yield put(
      odcAllowanceActions.exportOdcAllowanceReportSuccess({
        data: response.data,
      })
    );
  } catch (error) {
    yield put(odcAllowanceActions.exportOdcAllowanceReportFailure());
  }
}

//Watcher
export const odcAllowanceWatcher = [
  takeEvery(
    odcAllowanceActions.exportOdcAllowanceReportRequest,
    exportOdcAllowanceReportWorker
  ),
];
