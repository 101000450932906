import { AxiosRequestConfig, AxiosResponse } from "axios";
import { axiosInstance } from "../../config/axios.config";

type importEmployeeResponse = {
  errors: any;
  isSucceeded: boolean;
};
type uploadImageResponse = {
  data: any;
  errors: any;
  isSucceeded: boolean;
};
type actionType = {
  type: string;
  payload: any;
};

const uploadApi = {
  importEmployee: async ({
    payload,
  }: actionType): Promise<AxiosResponse<importEmployeeResponse, any>> => {
    const url = "ImportFile/SeedMasterData";
    const response = (await axiosInstance.post)<importEmployeeResponse>(
      url,
      payload
    );

    return response;
  },

  uploadImg: async ({
    payload,
  }: actionType): Promise<AxiosResponse<uploadImageResponse, any>> => {
    const response = (await axiosInstance.post)(
      "UploadFile",
      { formFile: payload },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  },

  uploadImage: async (
    payload: any
  ): Promise<AxiosResponse<uploadImageResponse, any>> => {
    const url = "/UploadFile";
    const { file, height, width } = payload;

    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "multipart/form-data;",
      },
      params: {
        width,
        height,
      },
    };
    const imageRes = await axiosInstance.post(url, file, config);
    return imageRes;
  },
  uploadFile: async (payload: {
    formData: any;
    config: any;
  }): Promise<AxiosResponse<uploadImageResponse, any>> => {
    const url = "/File/Upload-File";
    const imageRes = await axiosInstance.post(url, payload);
    return imageRes;
  },
};

export { uploadApi };
