import { API_ENDPOINT } from "@config/apiEndpoint.config";
import { AxiosResponse } from "axios";
import { axiosInstance } from "@config/axios.config";
import {
  IGetEEReportRequest,
  IGetEEReportResponse,
} from "./ee.model";
import { IBaseResponse } from "models/base.model";

const eeService = {
  getEEReport: async (
    data: IGetEEReportRequest
  ): Promise<
    AxiosResponse<IBaseResponse<IGetEEReportResponse>>
  > => {
    const url = API_ENDPOINT.EE.CAL_EE;
    const config = {
      params: {
        month: data.month,
      },
    };
    const response = (await axiosInstance.get)<any>(url, config);
    return response;
  },
};

export { eeService };
