import { call, put, takeEvery } from "redux-saga/effects";
import { NewPasswordRequest } from "../../pages/ForgotPasswordPage/components/NewPasswordInput";
import { phoneNumberRequest } from "../../pages/ForgotPasswordPage/components/PhoneNumberInput";
import { LoginRequest } from "../../pages/LoginPage/LoginPage";
import { authApi } from "./authApi";
import { userActions } from "../user/userSlice";
import { authActions } from "./authSlice";
import { ResetPasswordRequest } from "@pages/ResetPasswordPage/components/BirthdayInput";
import { ResendEmailRequest } from "@pages/ResetPasswordPage/components/EmailInput";

export type LoginType = {
  type: string;
  payload: { values: LoginRequest; onSuccess: () => void };
};

export type UpdatePhoneNumber = {
  type: string;
  payload: {
    newPhoneNumber: string;
    onSuccess?: () => void;
    onError?: () => void;
  };
};
export type UpdateEmailAddress = {
  type: string;
  payload: {
    newMail: string;
    onSuccess?: () => void;
    onError?: () => void;
  };
};

// Worker
export function* loginWorker(action: LoginType): any {
  try {
    const response = yield call<any>(authApi.login, {
      payload: action.payload,
    });
    if (response.isSucceeded) {
      yield put(authActions.loginSuccess(response.data));
      yield put(userActions.getCurrentUser());
      action.payload.onSuccess();
    }
    // TODO: handle callback | onSuccess
  } catch (error: any) {
    console.log({ error });
    yield put(authActions.loginFailure(error));
    // TODO: handle callback | onFailure
  }
}

export function* googleTokenWorker(): any {}

export type AddNewPasswordType = {
  type: string;
  payload: { values: NewPasswordRequest; onSuccess: () => void };
};
export function* addNewPasswordWorker(action: AddNewPasswordType): any {
  try {
    const response = yield call<any>(authApi.addNewPassword, {
      payload: action.payload,
    });
    if (response) {
      action.payload.onSuccess();
      yield put(authActions.addNewPasswordSuccess());
    }
    // TODO: handle callback | onSuccess
  } catch (error: any) {
    yield put(authActions.addNewPasswordFailure());
    // TODO: handle callback | onFailure
  }
}

export type CheckPhoneType = {
  type: string;
  payload: { values: phoneNumberRequest; onSuccess: () => void };
};

export type ResetPasswordType = {
  type: string;
  payload: { values: ResetPasswordRequest; onSuccess: () => void };
};

export type ResendEmailType = {
  type: string;
  payload: { values: ResendEmailRequest; onSuccess: () => void };
};

export function* checkPhoneNumberWorker(action: CheckPhoneType): any {
  try {
    const response = yield call<any>(authApi.checkPhoneNumber, {
      payload: action.payload.values,
    });
    if (response.isSucceeded) {
      action.payload.onSuccess();
      yield put(authActions.checkPhoneNumberSuccess());
    }
    // TODO: handle callback | onSuccess
  } catch (error: any) {
    yield put(authActions.checkPhoneNumberFailure());
    // TODO: handle callback | onFailure
  }
}

export function* updatePhoneNumberWorker(action: UpdatePhoneNumber): any {
  try {
    yield call<any>(authApi.updatePhoneNumber, { payload: action.payload });
    yield put(authActions.updatePhoneNumberSuccess());
    if (action.payload.onSuccess) {
      yield call(action.payload.onSuccess);
    }
  } catch (error: any) {
    yield put(authActions.updatePhoneNumberFailure());
    // TODO: handle callback | onFailure
    if (action.payload.onError) {
      yield call(action.payload.onError);
    }
  }
}
export function* updateEmailAddressWorker(action: UpdateEmailAddress): any {
  try {
    yield call<any>(authApi.updateEmailAddress, { payload: action.payload });
    yield put(authActions.updateEmailAddressSuccess());
    if (action.payload.onSuccess) {
      yield call(action.payload.onSuccess);
    }
  } catch (error: any) {
    yield put(authActions.updateEmailAddressFailure());
    // TODO: handle callback | onFailure
    if (action.payload.onError) {
      yield call(action.payload.onError);
    }
  }
}
export function* resetPasswordWorker(action: ResetPasswordType): any {
  try {
    const response = yield call<any>(authApi.resetPassword, {
      payload: action.payload.values,
    });
    if (response.isSucceeded) {
      action.payload.onSuccess();
      yield put(authActions.resetPasswordSuccess());
    }
    // TODO: handle callback | onSuccess
  } catch (error: any) {
    console.log({ error });
    yield put(authActions.resetPasswordFailure(error));
    // TODO: handle callback | onFailure
  }
}

export function* resendEmailWorker(action: ResendEmailType): any {
  try {
    const response = yield call<any>(authApi.resendEmail, {
      payload: action.payload.values,
    });
    if (response) {
      action.payload.onSuccess();
      yield put(authActions.resendEmailSuccess());
    }
    // TODO: handle callback | onSuccess
  } catch (error: any) {
    yield put(authActions.resendEmailFailure());
    // TODO: handle callback | onFailure
  }
}
//Watcher
export const authWatcher = [
  takeEvery(authActions.login, loginWorker),
  takeEvery(authActions.addNewPassword, addNewPasswordWorker),
  takeEvery(authActions.checkPhoneNumber, checkPhoneNumberWorker),
  takeEvery(authActions.resetPassword, resetPasswordWorker),
  takeEvery(authActions.resendEmail, resendEmailWorker),
  takeEvery(authActions.updatePhoneNumber, updatePhoneNumberWorker),
  takeEvery(authActions.updateEmailAddress, updateEmailAddressWorker),
];
