import { employeeReducer } from "../features/employee/employeeSlice";
import { jobRankReducer } from "../features/jobRank/jobRankSlice";
import { lineReducer } from "../features/line/lineSlice";
import { projectReducer } from "../features/project/projectSlice";
import { roleReducer } from "../features/role/roleSlice";
import { authReducer } from "../features/auth/authSlice";
import { uploadReducer } from "../features/upload/uploadSlice";
import { userReducer } from "../features/user/userSlice";
import { eventReducer } from "../features/event/eventSlice";
import { resourceAllocationReducer } from "../features/resource-allocation/resourceAllocation.slice";
import { eeReducer } from "../features/ee/ee.slice";
import { odcAllowanceReducer } from "../features/odc-allowance/odcAllowance.slice";
import { workDiaryReducer } from "../features/work-diary/workDiarySlice";
import { customerReducer } from "../features/customer/customerSlice";
import { chartReducer } from "@features/chart/chartSlice";
import { accountReducer } from "@features/account/accountSlice";
import { feedbackReducer } from "@features/feedback/feedbackSlice";
import { certificatesReducer } from "@features/certificates/certificatesSlice";
import { devicesReducer } from "@features/devices/devicesSlice";
import { resourcePlanningReducer } from "@features/resourcePlanning/resourcePlanning.slice";
import { todoTaskReducer } from "@features/todoTask/todoTaskSlice";

export const rootReducer = {
  user: userReducer,
  auth: authReducer,
  upload: uploadReducer,
  line: lineReducer,
  jobRank: jobRankReducer,
  role: roleReducer,
  project: projectReducer,
  employee: employeeReducer,
  event: eventReducer,
  resourceAllocation: resourceAllocationReducer,
  resourcePlanning: resourcePlanningReducer,
  ee: eeReducer,
  odcAllowance: odcAllowanceReducer,
  workDiary: workDiaryReducer,
  customer: customerReducer,
  chart: chartReducer,
  account: accountReducer,
  feedback: feedbackReducer,
  certificates: certificatesReducer,
  devices: devicesReducer,
  todoTask: todoTaskReducer,
};
