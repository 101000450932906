import { clearTokenWhenLogout } from "@utils/helpers";
import { call, put, takeEvery } from "redux-saga/effects";
import { userApi } from "./userApi";
import { TChangePassword, userActions } from "./userSlice";

type ActionType = {
  type: string;
  payload: any;
};

export function* userWorker({ payload }: ActionType): Generator {
  try {
    yield call(userApi.getAlluser, payload);
    // yield put(userActions.success(response));
    // TODO: handle callback | onSuccess
  } catch (error) {
    // yield put(userActions.failure(error));
    // TODO: handle callback | onFailure
  }
}

export function* getCurrentUserWorker(): any {
  try {
    const response = yield call(userApi.getCurrentUser);
    yield put(userActions.getCurrentUserSuccess(response));
    // yield put(userActions.receiveCurrentUser(response));
    // yield put(userActions.receiveCurrentUserSuccess());
    // TODO: handle callback | onSuccess
  } catch (error) {
    // yield put(userActions.failure(error));
    yield put(userActions.getCurrentUserFailure());
    // TODO: handle callback | onFailure
  }
}

export function* getUserProfileWorker(): any {
  try {
    const response = yield call(userApi.getUserProfile);
    yield put(userActions.getUserProfileSuccess(response));
  } catch (error) {
    yield put(userActions.getUserProfileFailure());
    // TODO: handle callback | onFailure
  }
}

export type LogoutType = {
  type: string;
  payload: { onSuccess: () => void };
};

export function* logoutWorker(action: LogoutType): any {
  try {
    clearTokenWhenLogout();
    action.payload.onSuccess();
    yield put(userActions.logoutSuccess());
    // TODO: handle callback | onSuccess
  } catch (error) {
    yield put(userActions.logoutFailure());
    // TODO: handle callback | onFailure
  }
}

export function* getListUserWorker({
  payload,
}: {
  payload: { account: string };
}): any {
  try {
    const response = yield call(userApi.getListUser, payload);
    yield put(userActions.getListUserSuccess(response));
  } catch (error) {
    yield put(userActions.getListUserFailure());
    // TODO: handle callback | onFailure
  }
}
export function* changePasswordWorker(action: TChangePassword): any {
  try {
    const response = yield call<any>(userApi.changePassword, {
      payload: action.payload,
    });
    if (response) {
      if (action.payload.onSuccess) {
        yield call(action.payload.onSuccess);
      }
      yield put(userActions.changePasswordSuccess());
    }
    // TODO: handle callback | onSuccess
  } catch (error: any) {
    yield put(userActions.changePasswordFailure());
    // TODO: handle callback | onFailure
  }
}
/*Watcher*/
export const userWatcher = [
  takeEvery(userActions.reducerName, userWorker),
  takeEvery(userActions.getCurrentUser, getCurrentUserWorker),
  takeEvery(userActions.logout, logoutWorker),
  takeEvery(userActions.getListUserRequest, getListUserWorker),
  takeEvery(userActions.changePassword, changePasswordWorker),
  takeEvery(userActions.getUserProfile, getUserProfileWorker),
];
