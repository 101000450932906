import { call, put, takeEvery } from "redux-saga/effects";
import { jobRankApi } from "./jobRankApi";
import { jobRankActions } from "./jobRankSlice";

type ActionType = {
  type: string;
  payload: any;
};

export function* getAllJobRankWorker({ payload }: ActionType): Generator {
  try {
    const response = yield call(jobRankApi.getAllJobRank, payload);

    yield put(jobRankActions.getAllJobRankSuccess(response));
    // TODO: handle callback | onSuccess
  } catch (error) {
    // yield put(uploadActions.failure(error));
    // TODO: handle callback | onFailure
  }
}

/*Watcher*/
export const jobRankWatcher = [
  takeEvery(jobRankActions.getAllJobRankRequest, getAllJobRankWorker),
];
