import { createSlice } from "@reduxjs/toolkit";
import { IPayloadAction } from "models/base.model";
import {
  IGetAllResourcePlanningResponse,
  IGetResourcePlanningRequest,
  IResourcePlanningItem,
  IResourcePlanningState,
  IUpdateEmployeePlanningResponse,
} from "./resourcePlanning.model";

const initialState: IResourcePlanningState = {
  getAllResourcePlanningResponse: null,
  getResourcePlanningResponse: null,
  loading: false,
  updateEmployeePlanningResponse: null,
};

const resourcePlanningSlice = createSlice({
  name: "resourcePlanning",
  initialState,
  reducers: {
    getAllResourcePlanningRequest(
      state,
      _action: IPayloadAction<IGetResourcePlanningRequest>
    ) {
      state.loading = true;
    },
    getAllResourcePlanningSuccess(
      state,
      action: IPayloadAction<IGetAllResourcePlanningResponse>
    ) {
      state.getAllResourcePlanningResponse = action.payload.data;
      state.loading = false;
    },
    getAllResourcePlanningFailure(state) {
      state.loading = false;
    },

    getResourcePlanningRequest(
      state,
      _action: IPayloadAction<IGetResourcePlanningRequest>
    ) {
      state.loading = true;
    },
    getResourcePlanningSuccess(
      state,
      action: IPayloadAction<IGetAllResourcePlanningResponse>
    ) {
      state.getResourcePlanningResponse = action.payload.data;
      state.loading = false;
    },
    getResourcePlanningFailure(state) {
      state.loading = false;
    },

    updateEmployeePlanningRequest(
      state,
      _action: IPayloadAction<IResourcePlanningItem>
    ) {
      state.loading = true;
    },
    updateEmployeePlanningSuccess(
      state,
      action: IPayloadAction<IUpdateEmployeePlanningResponse>
    ) {
      state.updateEmployeePlanningResponse = action.payload.data;
      state.loading = false;
    },
    updateEmployeePlanningFailure(state) {
      state.loading = false;
    },
  },
});

const { actions, reducer } = resourcePlanningSlice;

export {
  actions as resourcePlanningActions,
  reducer as resourcePlanningReducer,
};
