import { AxiosResponse } from "axios";
import { IPayloadAction } from "models/base.model";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  IGetAllResourceAllocationRequest,
  IGetAllResourceAllocationResponse,
} from "./resourceAllocation.model";
import { resourceAllocationService } from "./resourceAllocation.service";
import { resourceAllocationActions } from "./resourceAllocation.slice";

// Worker
export function* getAllResourceAllocationWorker({
  payload,
}: IPayloadAction<IGetAllResourceAllocationRequest>) {
  try {
    const response: AxiosResponse<IGetAllResourceAllocationResponse> =
      yield call(
        resourceAllocationService.getAllResourceAllocation,
        payload.data
      );
    yield put(
      resourceAllocationActions.getAllResourceAllocationSuccess({
        data: response.data,
      })
    );
  } catch (error) {
    yield put(resourceAllocationActions.getAllResourceAllocationFailure());
  }
}

//Watcher
export const resourceAllocationWatcher = [
  takeEvery(
    resourceAllocationActions.getAllResourceAllocationRequest,
    getAllResourceAllocationWorker
  ),
];
