import { createSlice } from "@reduxjs/toolkit";

type stateType = {
  getLineJuniorSeniorResponse: any;
  getLineJuniorSeniorLoading: boolean;
  getOnboardResignResponse: any;
  getOnboardResignLoading: boolean;
  getPDistributionResponse: any;
  getPDistributionLoading: boolean;
};

const initialState: stateType = {
  getLineJuniorSeniorResponse: null,
  getLineJuniorSeniorLoading: false,
  getOnboardResignResponse: null,
  getOnboardResignLoading: false,
  getPDistributionResponse: null,
  getPDistributionLoading: false,
};

const chartSlice = createSlice({
  name: "chart",
  initialState,
  reducers: {
    getLineJuniorSeniorRequest(state, _action) {
      state.getLineJuniorSeniorLoading = true;
    },
    getLineJuniorSeniorSuccess(state, { payload }) {
      state.getLineJuniorSeniorResponse = payload.data;
      state.getLineJuniorSeniorLoading = false;
    },
    getLineJuniorSeniorFailure(state) {
      state.getLineJuniorSeniorLoading = false;
    },

    getOnboardResignRequest(state, _action) {
      state.getLineJuniorSeniorLoading = true;
    },
    getOnboardResignSuccess(state, { payload }) {
      state.getOnboardResignResponse = payload.data;
      state.getOnboardResignLoading = false;
    },
    getOnboardResignFailure(state) {
      state.getOnboardResignLoading = false;
    },

    getPDistributionRequest(state, _action) {
      state.getPDistributionLoading = true;
    },
    getPDistributionSuccess(state, { payload }) {
      state.getPDistributionResponse = payload.data;
      state.getPDistributionLoading = false;
    },
    getPDistributionFailure(state) {
      state.getPDistributionLoading = false;
    },
  },
});

const { actions, reducer } = chartSlice;

export { actions as chartActions, reducer as chartReducer };
