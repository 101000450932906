import {
  showErrorNotification,
  showSuccessNotification,
} from "@utils/notification";
import { ICustomerRequest } from "models/customer";
import { call, put, takeEvery } from "redux-saga/effects";
import { customerApi } from "./customerApi";
import { customerActions } from "./customerSlice";

export function* getAllCustomerWorker({ payload }: any): Generator {
  try {
    const response = yield call(customerApi.getAllCustomer, payload);
    yield put(customerActions.getAllCustomerSuccess(response));
  } catch (error) {
    showErrorNotification("Get employees failure!");
    yield put(customerActions.getAllCustomerFailure());
  }
}

export function* getCustomerByIdWorker({
  payload,
}: {
  payload: string;
}): Generator {
  try {
    const response: any = yield call(customerApi.getCustomerById, payload);
    if (response.isSucceeded) {
      yield put(customerActions.getCustomerByIdSuccess(response));
    }
  } catch (error: any) {
    yield put(customerActions.getCustomerByIdFailure());
    showErrorNotification("Get customer failure!");
  }
}

export function* createCustomerWorker({
  payload,
}: {
  payload: {
    values: ICustomerRequest;
    searchParams: {
      search: string;
      pageNumber: number;
      pageSize: number;
    };
    onSuccess?: () => void;
    onError?: () => void;
  };
}): Generator {
  try {
    const response: any = yield call(customerApi.addCustomer, payload.values);
    if (response.isSucceeded) {
      yield put(customerActions.createCustomerSuccess(response));
      if (payload.onSuccess !== undefined) {
        yield call(payload.onSuccess);
      }
      showSuccessNotification("Create customer successfully");
      yield getAllCustomerWorker({ payload: payload.searchParams });
    }
  } catch (error: any) {
    yield put(customerActions.createCustomerFailure());
    showErrorNotification("Create customer failure!");
  }
}

export function* editCustomerWorker({
  payload,
}: {
  payload: {
    id: string;
    values: ICustomerRequest;
    searchParams: {
      search: string;
      pageNumber: number;
      pageSize: number;
    };
    onSuccess?: () => void;
    onError?: () => void;
  };
}): Generator {
  try {
    const response: any = yield call(customerApi.editCustomer, {
      id: payload.id,
      values: payload.values,
    });
    if (response.isSucceeded) {
      yield put(customerActions.editCustomerSuccess(response));
      if (payload.onSuccess !== undefined) {
        yield call(payload.onSuccess);
      }
      showSuccessNotification("Edit customer successfully");
      yield getAllCustomerWorker({ payload: payload.searchParams });
    }
  } catch (error: any) {
    yield put(customerActions.editCustomerFailure());
    showErrorNotification("Edit customer failure!");
  }
}

export function* deleteCustomerWorker({
  payload,
}: {
  payload: {
    id: string;
    searchParams: {
      search: string;
      pageNumber: number;
      pageSize: number;
    };
  };
}): Generator {
  try {
    const response: any = yield call(customerApi.deleteCustomer, payload.id);
    if (response.isSucceeded) {
      yield put(customerActions.deleteCustomerSuccess());
      yield getAllCustomerWorker({ payload: payload.searchParams });
      showSuccessNotification("Delete customer successfully!");
    }
  } catch (error) {
    showErrorNotification("Delete customer failure!");
    yield put(customerActions.deleteCustomerFailure());
  }
}

export const customerWatcher = [
  takeEvery(customerActions.getAllCustomerRequest, getAllCustomerWorker),
  takeEvery(customerActions.getCustomerByIdRequest, getCustomerByIdWorker),
  takeEvery(customerActions.createCustomerRequest, createCustomerWorker),
  takeEvery(customerActions.editCustomerRequest, editCustomerWorker),
  takeEvery(customerActions.deleteCustomerRequest, deleteCustomerWorker),
];
