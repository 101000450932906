import { call, put, takeEvery } from "redux-saga/effects";
import { feedbackAction } from "./feedbackSlice";
import { feedbackApi } from "./feedbackApi";
import {
  showErrorNotification,
  showSuccessNotification,
} from "@utils/notification";

interface ActionType {
  type: string;
  payload: any;
}

export function* getAllUserWorker(): Generator {
  try {
    const response = yield call(feedbackApi.getAllUser);
    yield put(feedbackAction.getAllUserSuccess(response));
  } catch (error) {
    showErrorNotification("Get User failure!");
    yield put(feedbackAction.getAllUserFailure());
  }
}

export function* sendMessageWorker({ payload }: ActionType): Generator {
  try {
    yield call(feedbackApi.sendMessage, payload);
    showSuccessNotification("Send message Success!");
    yield put(feedbackAction.sendMessSuccess());
    if (payload?.onSuccess) {
      yield put(payload?.onSuccess);
    }
  } catch (error) {
    showErrorNotification("Send message failure!");
    yield put(feedbackAction.sendMessFailure());
  }
}

export function* getAllMessageWorker({ payload }: ActionType): Generator {
  try {
    const response = yield call(feedbackApi.getAllMessage);
    yield put(feedbackAction.getAllMessageSuccess(response));

    if (payload?.onSuccess) {
      yield call(payload?.onSuccess, response);
    }
  } catch (error) {
    showErrorNotification("Get message failure");
    yield put(feedbackAction.getAllMessageFailure);
  }
}

export const feedbackWatcher = [
  takeEvery(feedbackAction.getAllUserRequest, getAllUserWorker),
  takeEvery(feedbackAction.sendMessageRequest, sendMessageWorker),
  takeEvery(feedbackAction.getAllMessageRequest, getAllMessageWorker),
];
