import { axiosInstance } from "@config";
import { API_ENDPOINT } from "@config/apiEndpoint.config";
import { AxiosResponse } from "axios";
import { IDevice, TBorrowDevice, TConfirmDevice, TReturnDevice } from "models";
import { IBaseResponse } from "models/base.model";

const devicesApi = {
  getAllAssets: async (): Promise<AxiosResponse<any, any>> => {
    return await axiosInstance.get<any>(API_ENDPOINT.ASSET.GET_ALL);
  },
  getDetailDevice: async (id: string): Promise<AxiosResponse<any, any>> => {
    return await axiosInstance.get<any>(`${API_ENDPOINT.ASSET.BASE_URL}/${id}`);
  },
  getByPIC: async (employeeId: string): Promise<AxiosResponse<any, any>> => {
    return await axiosInstance.get<any>(`${API_ENDPOINT.ASSET.GETBYPIC}/${employeeId}`);
  },
  delete: async (id: string): Promise<AxiosResponse<any, any>> => {
    return await axiosInstance.delete<any>(
      `${API_ENDPOINT.ASSET.BASE_URL}/${id}`
    );
  },
  filter: async (payload: any): Promise<AxiosResponse<any, any>> => {
    let url = API_ENDPOINT.ASSET.FILTER;
    if (payload.search) {
      url += `${payload.search}&PageNumber=${payload.pageNumber}&PageSize=${payload.pageSize}`;
    } else {
      url += `?PageNumber=${payload.pageNumber}&PageSize=${payload.pageSize}`;
    }
    const response = await axiosInstance.get<any>(url);
    return response;
  },
  borrow: async (payload: TBorrowDevice): Promise<AxiosResponse<any, any>> => {
    return await axiosInstance.post<any>(API_ENDPOINT.ASSET.BORROW, payload);
  },
  return: async (payload: TReturnDevice): Promise<AxiosResponse<any, any>> => {
    return await axiosInstance.post<any>(API_ENDPOINT.ASSET.RETURN, payload);
  },
  import: async (payload: any): Promise<AxiosResponse<IBaseResponse<any>>> => {
    const bodyFormData = new FormData();
    bodyFormData.append("file", payload);
    const response = await axiosInstance.post<any>(
      API_ENDPOINT.ASSET.IMPORT,
      bodyFormData
    );
    return response;
  },
  add: async (payload: IDevice): Promise<AxiosResponse<any, any>> => {
    const response = await axiosInstance.post<any>(
      API_ENDPOINT.ASSET.BASE_URL,
      payload
    );
    return response;
  },
  edit: async (payload: IDevice): Promise<AxiosResponse<any, any>> => {
    return await axiosInstance.put<any>(
      `${API_ENDPOINT.ASSET.BASE_URL}/${payload.id}`,
      payload
    );
  },
  confirm: async (payload: TConfirmDevice): Promise<AxiosResponse<any, any>> => {
    return await axiosInstance.post<any>(API_ENDPOINT.ASSET.CONFIRM, payload);
  },
  reject: async (payload: TConfirmDevice): Promise<AxiosResponse<any, any>> => {
    return await axiosInstance.post<any>(API_ENDPOINT.ASSET.REJECT, payload);
  },
};

export { devicesApi };
