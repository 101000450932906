import { employeeActions } from "@features/employee/employeeSlice";
import { IQueryUserPermission } from "@pages/UserPermission/components/ModalUserPermission/ModalUserPermission";
import { CheckExistRole } from "@utils/auth";
import { EmployeeRole } from "@utils/constants";
import {
  showErrorNotification,
  showSuccessNotification,
} from "@utils/notification";
import { call, put, takeEvery } from "redux-saga/effects";
import { employeeApi } from "./employeeApi";

interface ActionType {
  type: string;
  payload: any;
}

export function* getAllEmployeeWorker(): Generator {
  try {
    const response = yield call(employeeApi.getAllEmployee);
    yield put(employeeActions.getAllEmployeeSuccess(response));
  } catch (error) {
    showErrorNotification("Get employees failure!");
    yield put(employeeActions.getAllEmployeeFailure());
  }
}

export function* importEmployeeWorker({ payload }: ActionType): Generator {
  try {
    const response = yield call(employeeApi.importEmployee, payload.data);
    if (payload.onSuccess) {
      payload.onSuccess();
      yield getAllEmployeeWorker();
      yield put(employeeActions.importEmployeeSuccess(response));
    }
    showSuccessNotification("Import employees successfully");
  } catch (error: any) {
    const dataError = error?.response.data;
    if (dataError?.error) {
      if (dataError.errors[0]?.code) {
        showErrorNotification(
          `${dataError.errors[0].code} ${dataError.data[0].account}`
        );
      }
    } else {
      showErrorNotification("Import employees failure!");
    }

    yield put(employeeActions.importEmployeeFailure());
  }
}

export function* createEmployeeWorker({ payload }: ActionType): Generator {
  try {
    payload.employeeJobRanks = [{ jobRankId: payload.employeeJobRanks }];
    payload.employeePositions = payload.employeePositions?.map(
      (data: string) => {
        return { positionId: data };
      }
    );
    payload.employeeLines = payload.employeeLines?.map((data: string) => {
      return { lineId: data };
    });
    const response = yield call(employeeApi.importEmployee, [payload]);
    yield getAllEmployeeWorker();
    yield put(employeeActions.createNewEmployeeSuccess(response));
    showSuccessNotification("Create employees successfully");
  } catch (error: any) {
    console.log(error);
    const dataError = error?.response?.data;
    if (dataError?.error) {
      if (dataError.errors[0]?.code) {
        showErrorNotification(
          `${dataError.errors[0].code} ${dataError.data[0].account}`
        );
      }
    } else {
      showErrorNotification("Create employees failure!");
    }

    yield put(employeeActions.createNewEmployeeFailure());
  }
}
export function* editEmployeeWorker({ payload }: ActionType): Generator {
  try {
    if (typeof payload?.employeeJobRanks === "string") {
      payload.employeeJobRanks = [{ jobRankId: payload?.employeeJobRanks }];
    } else {
      payload.employeeJobRanks = payload?.employeeJobRanks?.map((data: any) => {
        return { jobRankId: data.jobRankId || data.value };
      });
    }
    payload.employeePositions = payload?.employeePositions?.map((data: any) => {
      if (typeof data === "string") {
        return { positionId: data };
      }
      return { positionId: data.positionId || data.value };
    });
    payload.employeeLines = payload?.employeeLines?.map((data: any) => {
      if (typeof data === "string") {
        return { lineId: data };
      }
      return { lineId: data.lineId || data.value };
    });
    const response = yield call(employeeApi.importEmployee, [payload]);
    if (!CheckExistRole(payload, EmployeeRole)) {
      yield getAllEmployeeWorker();
    }
    yield put(employeeActions.editEmployeeSuccess(response));
    if (payload?.onSuccess) {
      yield call(payload?.onSuccess);
    } else {
      showSuccessNotification("Edit employees successfully");
    }
  } catch (error: any) {
    console.log(error);
    const dataError = error?.response?.data;
    if (dataError?.error) {
      if (dataError.errors[0]?.code) {
        showErrorNotification(
          `${dataError.errors[0].code} ${dataError.data[0].account}`
        );
      }
    } else {
      showErrorNotification("Edit employees failure!");
    }

    yield put(employeeActions.editEmployeeFailure());
  }
}
export function* getDetailEmployeeWorker({ payload }: ActionType): Generator {
  try {
    const response = yield call(employeeApi.getDetailEmployee, payload);
    yield put(employeeActions.getDetailEmployeeSuccess(response));
  } catch (error) {
    showErrorNotification("Get detail employee failure!");
    yield put(employeeActions.getDetailEmployeeFailure());
  }
}

export function* getCheEmployeeWorker({ payload }: ActionType): Generator {
  try {
    const response: any = yield call(employeeApi.getCheEmployee, payload.id);
    if (response.isSucceeded) {
      payload.onSuccess(response.data.che);
      yield put(employeeActions.getCheEmployeeSuccess(response));
    }
  } catch (error) {
    showErrorNotification("Get che employee failure!");
    yield put(employeeActions.getCheEmployeeFailure());
  }
}

export function* createCheEmployeeWorker({ payload }: ActionType): Generator {
  try {
    const response: any = yield call(
      employeeApi.createCheEmployee,
      payload.data
    );
    if (response.isSucceeded) {
      payload.onSuccess();
      yield put(employeeActions.createCheEmployeeSuccess());
    }
  } catch (error) {
    showErrorNotification("Create che employee failure!");
    yield put(employeeActions.createCheEmployeeFailure());
  }
}

export function* editCheEmployeeWorker({ payload }: ActionType): Generator {
  try {
    const response: any = yield call(employeeApi.editCheEmployee, payload.data);
    if (response.isSucceeded) {
      payload.onSuccess();
      yield put(employeeActions.editCheEmployeeSuccess());
    }
  } catch (error: any) {
    if (
      error?.response?.data?.errors[0]?.code === "User does not have permission"
    ) {
      showErrorNotification("User does not have permission!");
    } else {
      showErrorNotification("Edit che employee failure!");
    }
    yield put(employeeActions.editCheEmployeeFailure());
  }
}

export function* deleteCheEmployeeWorker({ payload }: ActionType): Generator {
  try {
    const response: any = yield call(
      employeeApi.deleteCheEmployee,
      payload.data
    );
    if (response.isSucceeded) {
      payload.onSuccess();
      yield put(employeeActions.deleteCheEmployeeSuccess());
    }
  } catch (error: any) {
    if (
      error?.response?.data?.errors[0]?.code === "User does not have permission"
    ) {
      showErrorNotification("User does not have permission!");
    } else {
      showErrorNotification("Delete che employee failure!");
    }
    yield put(employeeActions.deleteCheEmployeeFailure());
  }
}

export function* getSkillEmployeeWorker({ payload }: ActionType): Generator {
  try {
    const response: any = yield call(employeeApi.getSkillEmployee, payload.id);
    if (response.isSucceeded) {
      // payload.onSuccess(response.data.che);
      yield put(employeeActions.getSkillEmployeeSuccess(response));
    }
  } catch (error) {
    showErrorNotification("Get skill employee failure!");
    yield put(employeeActions.getSkillEmployeeFailure());
  }
}

export function* createSkillEmployeeWorker({ payload }: ActionType): Generator {
  try {
    const response: any = yield call(employeeApi.createSkillEmployee, payload);

    if (response.isSucceeded) {
      // payload.onSuccess(response.data);
      yield put(employeeActions.createSkillEmployeeSuccess(response));
      yield getSkillEmployeeWorker({ type: "", payload });
      showSuccessNotification("Create skill successful!");
    }
  } catch (error) {
    showErrorNotification("Create skill failure!");
    yield put(employeeActions.createSkillEmployeeFailure());
  }
}

export function* getCVEmployeeWorker({ payload }: ActionType): Generator {
  try {
    const response: any = yield call(employeeApi.getCVEmployee, payload.id);
    if (response.isSucceeded) {
      // payload.onSuccess(response.data.che);
      yield put(employeeActions.getCVEmployeeSuccess(response));
    }
  } catch (error) {
    showErrorNotification("Get CV employee failure!");
    yield put(employeeActions.getCVEmployeeFailure());
  }
}

export function* createCVEmployeeWorker({ payload }: ActionType): Generator {
  try {
    yield call(employeeApi.createCVEmployee, {
      url: payload.url,
      name: payload.name,
      employeeId: payload.employeeId,
    });
    yield put(employeeActions.getCVEmployeeRequest({ id: payload.employeeId }));
    yield put(employeeActions.createCVEmployeeSuccess());
    showSuccessNotification("Upload CV successful!");
  } catch (error) {
    showErrorNotification("Upload CV failure!");
    yield put(employeeActions.createCVEmployeeFailure());
  }
}

export function* deleteCVEmployeeWorker({ payload }: ActionType): Generator {
  try {
    const responseFile: any = yield call(
      employeeApi.deleteCVEmployee,
      payload.id
    );
    if (responseFile.isSucceeded) {
      if (payload.hasOwnProperty("onSuccess")) {
        yield call(payload.onSuccess);
      }
      yield put(employeeActions.deleteCVEmployeeSuccess());
      showSuccessNotification("Delete CV successful!");
    }
  } catch (error) {
    showErrorNotification("Upload CV failure!");
    payload.onFailed();
    yield put(employeeActions.createCVEmployeeFailure());
  }
}

export function* getEmployeeProjectsWorker({ payload }: ActionType): Generator {
  try {
    const response = yield call(employeeApi.getEmployeeProjects, payload);
    yield put(employeeActions.getEmployeeProjectsSuccess(response));
  } catch (error) {
    showErrorNotification("Get employee's project failure!");
    yield put(employeeActions.getEmployeeProjectsFailure());
  }
}

export function* getEmployeeMonthBirthdaysWorker({
  payload,
}: ActionType): Generator {
  try {
    const response = yield call(
      employeeApi.getEmployeeByMonthBirthday,
      payload
    );
    yield put(employeeActions.getEmployeeMonthBirthdaySuccess(response));
    // TODO: handle callback | onSuccess
    if (payload.hasOwnProperty("onSuccess")) {
      yield call(payload.onSuccess);
    }
  } catch (error) {
    yield put(employeeActions.getEmployeeMonthBirthdayFailure());
    // TODO: handle callback | onError
    if (payload.hasOwnProperty("onError")) {
      yield call(payload.onError);
    }
  }
}

export function* getEmployeeExpireWorker({ payload }: ActionType): Generator {
  try {
    const response = yield call(employeeApi.getEmployeeByExpire, payload);
    yield put(employeeActions.getEmployeeExpireSuccess(response));
    // TODO: handle callback | onSuccess
    if (payload.hasOwnProperty("onSuccess")) {
      yield call(payload.onSuccess);
    }
  } catch (error) {
    yield put(employeeActions.getEmployeeExpireFailure());
    // TODO: handle callback | onError
    if (payload.hasOwnProperty("onError")) {
      yield call(payload.onError);
    }
  }
}

export function* getEmployeeOnboardComingWorker({
  payload,
}: ActionType): Generator {
  try {
    const response = yield call(employeeApi.getEmployeeOnboardComing, payload);
    yield put(employeeActions.getEmployeeOnboardComingSuccess(response));
    // TODO: handle callback | onSuccess
    if (payload.hasOwnProperty("onSuccess")) {
      yield call(payload.onSuccess);
    }
  } catch (error) {
    yield put(employeeActions.getEmployeeOnboardComingFailure());
    // TODO: handle callback | onError
    if (payload.hasOwnProperty("onError")) {
      yield call(payload.onError);
    }
  }
}

export function* getEmployeeOnboard(): Generator {
  try {
    const response = yield call(employeeApi.getEmployeeOnboard);
    yield put(employeeActions.getEmployeeOnboardSuccess(response));
  } catch (error) {
    showErrorNotification("Get employee's onboard failure!");
    yield put(employeeActions.getEmployeeOnboardFailure());
  }
}

export function* getEmployeeJobGrade(): Generator {
  try {
    const response = yield call(employeeApi.getEmployeeJobGrade);
    yield put(employeeActions.getEmployeeJobGradeSuccess(response));
  } catch (error) {
    showErrorNotification("Get employee's job grade failure!");
    yield put(employeeActions.getEmployeeJobGradeFailure());
  }
}

export function* createEmployeeOnboard({ payload }: ActionType): Generator {
  try {
    const response: any = yield call(
      employeeApi.createEmployeeOnboard,
      payload
    );
    if (response.isSucceeded) {
      yield put(employeeActions.createEmployeeOnboardSuccess(response));
      yield getEmployeeOnboard();
      showSuccessNotification("Create employee's onboard successful!");
    }
  } catch (error) {
    showErrorNotification("Create employee's onboard failure!");
    yield put(employeeActions.createEmployeeOnboardFailure());
  }
}

export function* deleteEmployeeOnboardWorker({
  payload,
}: ActionType): Generator {
  try {
    yield call(employeeApi.deleteEmployeeOnboard, payload);
    yield getEmployeeOnboard();
    yield put(employeeActions.deleteEmployeeOnboardSuccess());
    showSuccessNotification("Delete employee onboard successful!");
  } catch (error) {
    showErrorNotification("Delete employee onboard failure!");
    yield put(employeeActions.deleteEmployeeOnboardFailure());
  }
}

export function* editEmployeeOnboardWorker({ payload }: ActionType): Generator {
  try {
    const responseFile: any = yield call(
      employeeApi.editEmployeeOnboard,
      payload
    );
    if (responseFile.isSucceeded) {
      yield getEmployeeOnboard();
      yield put(employeeActions.editEmployeeOnboardSuccess(responseFile));
      showSuccessNotification("Edit employee onboard successful!");
    }
  } catch (error) {
    showErrorNotification("Edit employee onboard failure!");
    yield put(employeeActions.editEmployeeOnboardFailure());
  }
}

export function* searchEmployeeByAccount({
  payload,
}: {
  payload: IQueryUserPermission;
}): Generator {
  try {
    const response = yield call(employeeApi.searchEmployeeByAccount, payload);
    yield put(employeeActions.searchEmployeeSuccess(response));
  } catch (error) {
    showErrorNotification("Get employees failure!");
    yield put(employeeActions.searchEmployeeFailure());
  }
}

export function* updatePhoneEmployeeWorker({ payload }: ActionType): Generator {
  try {
    const response = yield call(employeeApi.updatePhoneEmployee, payload);
    yield put(employeeActions.updatePhoneEmployeeSuccess(response));
  } catch (error) {
    showErrorNotification("Update phone employee failure!");
    yield put(employeeActions.updatePhoneEmployeeFailure());
  }
}

export function* updateEmployeeNotesWorker({ payload }: ActionType): Generator {
  try {
    const response: any = yield call(employeeApi.updateEmployeeNotes, payload);
    if (response?.isSucceeded) {
      yield put(employeeActions.updateEmployeeNotesSuccess());
      if (payload?.onSuccess) {
        yield call(payload?.onSuccess, payload);
      }
    }
  } catch (error) {
    showErrorNotification("Update employee notes failure!");
    yield put(employeeActions.updateEmployeeNotesFailure());
  }
}

export function* getCertificatesWorker({ payload }: ActionType): Generator {
  try {
    const response: any = yield call(employeeApi.getCertificates, payload);
    yield put(employeeActions.getCertificatesSuccess(response));
  } catch {
    showErrorNotification("Get Certificates failure!");
    yield put(employeeActions.getCertificatesFailure());
  }
}

export function* addCertificatesWorker({ payload }: ActionType): Generator {
  try {
    const response: any = yield call(
      employeeApi.addCertificates,
      payload?.tempValue
    );
    if (payload?.onSuccess) {
      payload?.onSuccess();
    }
    yield put(employeeActions.addCertificatesSuccess(response));
  } catch (error) {
    showErrorNotification("Add certificates failure!");
    yield put(employeeActions.addCertificatesFailure());
  }
}

export function* deleteCertificatesWorker({ payload }: ActionType): Generator {
  try {
    const response: any = yield call(
      employeeApi.deleteCertificates,
      payload?.value
    );
    if (payload?.onSuccess) {
      payload?.onSuccess();
    }

    yield put(employeeActions.deleteCertificatesSuccess(response));
  } catch (error) {
    showErrorNotification("Delete certificates failure!");
    yield put(employeeActions.deleteCertificatesFailure());
  }
}

/*Watcher*/
export const employeeWatcher = [
  takeEvery(employeeActions.getAllEmployeeRequest, getAllEmployeeWorker),
  takeEvery(employeeActions.importEmployeeRequest, importEmployeeWorker),
  takeEvery(employeeActions.getDetailEmployeeRequest, getDetailEmployeeWorker),
  takeEvery(employeeActions.getCheEmployeeRequest, getCheEmployeeWorker),
  takeEvery(employeeActions.createCheEmployeeRequest, createCheEmployeeWorker),
  takeEvery(employeeActions.editCheEmployeeRequest, editCheEmployeeWorker),
  takeEvery(employeeActions.deleteCheEmployeeRequest, deleteCheEmployeeWorker),
  takeEvery(employeeActions.getSkillEmployeeRequest, getSkillEmployeeWorker),
  takeEvery(
    employeeActions.createSkillEmployeeRequest,
    createSkillEmployeeWorker
  ),
  takeEvery(employeeActions.getCVEmployeeRequest, getCVEmployeeWorker),
  takeEvery(employeeActions.createCVEmployeeRequest, createCVEmployeeWorker),
  takeEvery(employeeActions.deleteCVEmployeeRequest, deleteCVEmployeeWorker),
  takeEvery(employeeActions.getEmployeeProjects, getEmployeeProjectsWorker),
  takeEvery(
    employeeActions.getEmployeeMonthBirthdayRequest,
    getEmployeeMonthBirthdaysWorker
  ),
  takeEvery(employeeActions.getEmployeeExpireRequest, getEmployeeExpireWorker),
  takeEvery(
    employeeActions.getEmployeeOnboardComingRequest,
    getEmployeeOnboardComingWorker
  ),
  takeEvery(employeeActions.getEmployeeOnboardRequest, getEmployeeOnboard),
  takeEvery(employeeActions.getEmployeeJobGradeRequest, getEmployeeJobGrade),
  takeEvery(
    employeeActions.createEmployeeOnboardRequest,
    createEmployeeOnboard
  ),
  takeEvery(
    employeeActions.deleteEmployeeOnboardRequest,
    deleteEmployeeOnboardWorker
  ),
  takeEvery(
    employeeActions.editEmployeeOnboardRequest,
    editEmployeeOnboardWorker
  ),
  takeEvery(employeeActions.createNewEmployeeRequest, createEmployeeWorker),
  takeEvery(employeeActions.editEmployeeRequest, editEmployeeWorker),
  takeEvery(employeeActions.searchEmployeeRequest, searchEmployeeByAccount),
  takeEvery(
    employeeActions.updatePhoneEmployeeRequest,
    updatePhoneEmployeeWorker
  ),
  takeEvery(employeeActions.updateEmployeeNotes, updateEmployeeNotesWorker),
  takeEvery(employeeActions.getCertificates, getCertificatesWorker),
  takeEvery(employeeActions.addCertificates, addCertificatesWorker),
  takeEvery(employeeActions.deleteCertificates, deleteCertificatesWorker),
];
