import { CheckExistRole } from "@utils/auth";
import {
  EmployeeRole,
  ListRoutesEmployee,
} from "@utils/constants";
import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "../config/hooks";

function PermissionRoute(props: any) {
  const location = useLocation();
  const user = useAppSelector((state) => state.user.user);

  // check to right navigate with employee
  if (
    CheckExistRole(user, EmployeeRole) &&
    !ListRoutesEmployee(user?.employeeInfo?.id)?.includes(location.pathname)
  ) {
    return (
      <Navigate
        to={{
          pathname: `/employees/${user?.employeeInfo?.id}`,
        }}
      />
    );
  }

  // check to right navigate with HR Admin
  // if (
  //   CheckExistRole(user, AdminHRRole) &&
  //   !ListSidebarAdmin.includes(location.pathname) &&
  //   !location.pathname.includes("/admin/edit-onboard") &&
  //   !location.pathname.includes("/events/") &&
  //   !ListRoutesEmployee(user?.employeeInfo?.id)?.includes(location.pathname)
  // ) {
  //   return (
  //     <Navigate
  //       to={{
  //         pathname: "/admin/list-onboard",
  //       }}
  //     />
  //   );
  // }
  return props.children;
}

export default PermissionRoute;
