import {
  SettingOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  MessageOutlined,
  MailOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "@config/hooks";
import { userActions } from "@features/user/userSlice";
import { AvatarPlaceholder } from "@utils/media";
import { Dropdown, MenuProps } from "antd";
import { AiOutlineReconciliation } from "react-icons/ai";
import { BiLogOut } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { NavbarLink, UserBox, UserImg, UserInfo } from "./HeaderStyled";
import { InfoLogout } from "./ModalInfoAdminStyled";
import { AdminHRRole, AnhVD7, BulRole, LeaderRole } from "@utils/constants";

// const DropdownAvatar = () => {
//   const { user } = useAppSelector((state) => state.user);
//   // const { t } = useTranslation();
//   const t = (val: string) => val;
//   const dispatch = useAppDispatch();

//   const navigate = useNavigate();

//   const handleLogout = () => {
//     dispatch(
//       userActions.logout({
//         onSuccess: () => {
//           navigate("/");
//         },
//       })
//     );
//     // navigate("/");
//   };

//   return (
//     <Wrapper>
//       <Menu
//         style={{
//           borderRadius: "10px",
//           top: "4px",
//           left: "12px",
//           width: "150px",
//         }}
//         items={[
//           {
//             key: "1",
//             label: (
//               <InfoLogout
//               // onClick={handleLogout}
//               >
//                 <Link to="/Profile">
//                   <UserOutlined />
//                   <span className="span-logout">{t("Profile")}</span>
//                 </Link>
//               </InfoLogout>
//             ),
//           },
//           {
//             key: "1",
//             label: (
//               <InfoLogout
//               // onClick={handleLogout}
//               >
//                 <Link to="/history-action">
//                   <AiOutlineReconciliation />
//                   <span className="span-logout">{t("History Action")}</span>
//                 </Link>
//               </InfoLogout>
//             ),
//           },
//           [BulRole].includes(user?.role ? `${user?.role}` : "")
//             ? {
//                 key: "3",
//                 label: (
//                   <InfoLogout
//                   // onClick={handleLogout}
//                   >
//                     <Link to="/Setting">
//                       <SettingOutlined />
//                       <span className="span-logout">{t("Setting")}</span>
//                     </Link>
//                   </InfoLogout>
//                 ),
//               }
//             : null,
//           [BulRole].includes(user?.role ? `${user?.role}` : "")
//             ? {
//                 key: "4",
//                 label: (
//                   <InfoLogout
//                   // onClick={handleLogout}
//                   >
//                     <Link to="/user-permission">
//                       <UsergroupAddOutlined />
//                       <span className="span-logout">
//                         {t("User Permission")}
//                       </span>
//                     </Link>
//                   </InfoLogout>
//                 ),
//               }
//             : null,
//           {
//             key: "2",
//             label: (
//               <InfoLogout onClick={handleLogout}>
//                 <BiLogOut />
//                 <span className="span-logout">{t("Logout")}</span>
//               </InfoLogout>
//             ),
//           },
//         ]}
//       />
//     </Wrapper>
//   );
// };

function ModalInfoAdmin() {
  const { user } = useAppSelector((state) => state.user);
  // const { t } = useTranslation();
  const t = (val: string) => val;
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(
      userActions.logout({
        onSuccess: () => {
          navigate("/");
        },
      })
    );
  };

  const roleName = !user
    ? ""
    : user.role === BulRole
    ? "BU Leader"
    : user.role === LeaderRole
    ? "Leader"
    : user.role === AdminHRRole
    ? "HR Admin"
    : "Employee";
  const avatar = user && user.avatar ? user.avatar : AvatarPlaceholder;
  const items: MenuProps["items"] = [
    [BulRole].includes(user?.role ? `${user?.role}` : "") &&
    user?.userName.toLowerCase() == AnhVD7
      ? {
          key: "101",
          label: (
            <InfoLogout>
              <Link to="/todo-task">
                <FileDoneOutlined />
                <span className="span-logout">{t("Todo Task")}</span>
              </Link>
            </InfoLogout>
          ),
        }
      : null,
    {
      key: "1",
      label: (
        <InfoLogout>
          <Link to="/Profile">
            <UserOutlined />
            <span className="span-logout">{t("Profile")}</span>
          </Link>
        </InfoLogout>
      ),
    },
    {
      key: "2",
      label: (
        <InfoLogout>
          <Link to="/history-action">
            <AiOutlineReconciliation />
            <span className="span-logout">{t("History Action")}</span>
          </Link>
        </InfoLogout>
      ),
    },
    {
      key: "3",
      label: (
        <InfoLogout>
          <Link to="/feedback">
            <MessageOutlined />
            <span className="span-logout">{t("Feedback")}</span>
          </Link>
        </InfoLogout>
      ),
    },
    [BulRole].includes(user?.role ? `${user?.role}` : "")
      ? {
          key: "4",
          label: (
            <InfoLogout>
              <Link to="/Setting">
                <SettingOutlined />
                <span className="span-logout">{t("Setting")}</span>
              </Link>
            </InfoLogout>
          ),
        }
      : null,
    [BulRole].includes(user?.role ? `${user?.role}` : "") &&
    user?.userName.toLowerCase() == AnhVD7
      ? {
          key: "5",
          label: (
            <InfoLogout>
              <Link to="/user-permission">
                <UsergroupAddOutlined />
                <span className="span-logout">{t("User Permission")}</span>
              </Link>
            </InfoLogout>
          ),
        }
      : null,
    user?.userName.toLowerCase() == AnhVD7
      ? {
          key: "7",
          label: (
            <InfoLogout>
              <Link to="/log-email">
                <MailOutlined />
                <span className="span-logout">{t("Log Email")}</span>
              </Link>
            </InfoLogout>
          ),
        }
      : null,
    {
      key: "6",
      label: (
        <InfoLogout onClick={handleLogout}>
          <BiLogOut />
          <span className="span-logout">{t("Logout")}</span>
        </InfoLogout>
      ),
    },
  ];
  return (
    <Dropdown menu={{ items }} trigger={["click"]}>
      <span onClick={(e) => e.preventDefault()}>
        <UserBox>
          <NavbarLink className="nav-link nav-user">
            <UserImg src={avatar} alt="user-avatar" />
            <UserInfo>
              <p className="user-name">{user && user.userName}</p>
              <p className="user-job">{user && roleName}</p>
            </UserInfo>
          </NavbarLink>
        </UserBox>
      </span>
    </Dropdown>
  );
}

export default ModalInfoAdmin;
