import { createSlice } from "@reduxjs/toolkit";

type stateType = {
  getAllProjectResponse: any;
  pagination: any;
  loadProjectPlan: boolean;
  loadProjectPlanResponse: any;
};

const initialState: stateType = {
  getAllProjectResponse: null,
  pagination: null,
  loadProjectPlan: false,
  loadProjectPlanResponse: null,
};

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    getAllProjectRequest(state, _action) {
      return state;
    },
    getAllProjectSuccess(state, { payload }) {
      state.getAllProjectResponse = payload.data;
      state.pagination = payload.metaData;
    },
    getAllProjectFailure(state) {
      return state;
    },
    // load project plan
    loadProjectPlanRequest(state, _action) {
      state.loadProjectPlan = true;
    },
    loadProjectPlanSuccess(state, { payload }) {
      state.loadProjectPlanResponse = payload.data;
      state.loadProjectPlan = false;
    },
    loadProjectPlanFailure(state) {
      state.loadProjectPlan = false;
    },
  },
});

const { actions, reducer } = projectSlice;

export { actions as projectActions, reducer as projectReducer };
