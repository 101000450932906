export const MENU = {
  DASHBOARD: "1",
  EMPLOYEE_MANAGEMENT: "2",
  RESOURCE_PLANNING: "3",
  RESOURCE_ALLOCATION: "4",
  EE: "5",
  ODC_ALLOWANCE: "6",
  CUSTOMER_MANAGEMENT: "7",
  MEETING_NOTE: "8",
  CERTIFICATE: "9",
  DEVICE: "10",
  KPI: "11",
  PROJECT_PLAN: "12",
};

export const LIST_MENU = [
  {
    value: 1,
    label: "Dashboard",
    path: "/",
  },
  {
    value: 11,
    label: "KPI",
    path: "/kpi-page",
  },
  {
    value: 2,
    label: "Employee Management",
    path: "/employees",
  },
  {
    value: 3,
    label: "Resource Planning",
    path: "/planning",
  },
  {
    value: 4,
    label: "Resource Allocation",
    path: "/allocation",
  },
  {
    value: 12,
    label: "Project Plan",
    path: "/project-plan",
  },
  // {
  //   value: 5,
  //   label: "EE",
  //   path: "/ee",
  // },
  // {
  //   value: 6,
  //   label: "ODC Allowance",
  //   path: "/odcallowance",
  // },
  {
    value: 7,
    label: "Customer Management",
    path: "/customer",
  },
  {
    value: 8,
    label: "Meeting Note",
    path: "/work-diary",
  },
  {
    value: 9,
    label: "Certificate",
    path: "/certificates",
  },
  {
    value: 10,
    label: "Device Management",
    path: "/devices",
  },
];
