import { createSlice } from "@reduxjs/toolkit";
import { IPayloadAction } from "models/base.model";
import {
  IExportOdcAllowanceReport,
  IExportOdcAllowanceReportRequest,
  IOdcAllowanceState,
} from "./odcAllowance.model";

const initialState: IOdcAllowanceState = {
  exportOdcAllowanceReport: null,
  exportOdcAllowanceReportLoading: false,
};

const odcAllowanceSlice = createSlice({
  name: "odcallowance",
  initialState,
  reducers: {
    exportOdcAllowanceReportRequest(
      state,
      _action: IPayloadAction<IExportOdcAllowanceReportRequest>
    ) {
      state.exportOdcAllowanceReportLoading = true;
    },
    exportOdcAllowanceReportSuccess(
      state,
      action: IPayloadAction<IExportOdcAllowanceReport>
    ) {
      state.exportOdcAllowanceReport = action.payload.data;
      state.exportOdcAllowanceReportLoading = false;
    },
    exportOdcAllowanceReportFailure(state) {
      state.exportOdcAllowanceReportLoading = false;
    },
  },
});

const { actions, reducer } = odcAllowanceSlice;

export { actions as odcAllowanceActions, reducer as odcAllowanceReducer };
