import { createSlice } from "@reduxjs/toolkit";

type stateType = { getAllJobRankResponse: any; pagination: any };

const initialState: stateType = {
  getAllJobRankResponse: null,
  pagination: null,
};

const jobRankSlice = createSlice({
  name: "jobRank",
  initialState,
  reducers: {
    getAllJobRankRequest(state, _action) {
      return state;
    },
    getAllJobRankSuccess(state, { payload }) {
      state.getAllJobRankResponse = payload.data;
      state.pagination = payload.metaData;
    },
    getAllJobRankFailure(state) {
      return state;
    },
  },
});

const { actions, reducer } = jobRankSlice;

export { actions as jobRankActions, reducer as jobRankReducer };
