import { createSlice } from "@reduxjs/toolkit";
import { IDevice } from "models";

type stateType = {
  assetList: IDevice[];
  loading: boolean;
  getByPICResponse: IDevice[];
  getByPICLoading: boolean;
  confirmAssetLoading: boolean;
  rejectDeviceLoading: boolean;
  assetDetail: IDevice;
};

const initialState: stateType = {
  assetList: [],
  loading: false,
  getByPICResponse: [],
  getByPICLoading: false,
  confirmAssetLoading: false,
  rejectDeviceLoading: false,
  assetDetail: {},
};

const devicesSlice = createSlice({
  name: "devices",
  initialState,
  reducers: {
    getAllAssetsRequest(state) {
      state.loading = true;
    },

    getAllAssetsSuccess(state, { payload }) {
      state.loading = false;
      state.assetList = payload?.data;
    },
    getAllAssetsFailure(state) {
      state.loading = false;
    },

    getByPICRequest(state, _action) {
      state.getByPICLoading = true;
    },
    getByPICSuccess(state, { payload }) {
      state.getByPICLoading = false;
      state.getByPICResponse = payload?.data;
    },
    getByPICFailure(state) {
      state.getByPICLoading = false;
    },

    filterAssetsRequest(state, _payload) {
      state.loading = true;
    },
    filterAssetsSuccess(state, { payload }) {
      state.loading = false;
      state.assetList = payload?.data;
    },
    filterAssetsFailure(state, error) {
      state.loading = false;
    },
    getDetailAssetRequest(state, _action) {
      state.loading = true;
    },
    getDetailAssetSuccess(state, { payload }) {
      const { data } = payload;
      state.loading = false;
      state.assetDetail = data;
    },
    getDetailAssetFailure(state) {
      state.loading = false;
    },
    deleteAssetRequest(state, _action) {
      state.loading = true;
    },
    deleteAssetSuccess(state) {
      state.loading = false;
    },
    deleteAssetFailure(state) {
      state.loading = false;
    },
    addDeviceRequest(state, _action) {
      state.loading = true;
    },
    addMultiDeviceRequest(state, _action) {
      state.loading = true;
    },
    borrowDeviceRequest(state, _action) {
      state.loading = true;
    },
    borrowDeviceSuccess(state, _action) {
      state.loading = false;
    },
    borrowDeviceFailure(state, _action) {
      state.loading = false;
    },
    returnDeviceRequest(state, _action) {
      state.loading = true;
    },
    returnDeviceSuccess(state, _action) {
      state.loading = false;
    },
    returnDeviceFailure(state, _action) {
      state.loading = true;
    },
    reportDeviceBrokenRequest(state, _action) {
      state.loading = true;
    },

    confirmDeviceRequest(state, _action) {
      state.confirmAssetLoading = true;
    },
    confirmDeviceSuccess(state, _action) {
      state.confirmAssetLoading = false;
    },
    confirmDeviceFailure(state, _action) {
      state.confirmAssetLoading = false;
    },
    rejectDeviceRequest(state, _action) {
      state.rejectDeviceLoading = true;
    },
    rejectDeviceSuccess(state, _action) {
      state.rejectDeviceLoading = false;
    },
    rejectDeviceFailure(state, _action) {
      state.rejectDeviceLoading = false;
    },
  },
});

const { actions, reducer } = devicesSlice;

export { actions as devicesActions, reducer as devicesReducer };
