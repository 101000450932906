import { WorkTime } from "@pages/EmployeeDetailPage/components/ProjectCalendar";
import { createSlice } from "@reduxjs/toolkit";
import { COLORS } from "@utils/constants";
import { IEmployeeOnboard } from "models";
import moment from "moment";

type stateType = {
  currentListEmployee: any;
  getAllEmployeeResponse: any;
  getAllEmployeeLoading: boolean;
  importEmployeeResponse: any;
  isImportEmployeeSucceeded: boolean;
  importEmployeeLoading: boolean;
  getDetailEmployeeLoading: boolean;
  getDetailEmployeeResponse: any;
  getCheEmployeeLoading: boolean;
  getCheEmployeeResponse: any;
  createCheEmployeeLoading: boolean;
  editCheEmployeeLoading: boolean;
  deleteCheEmployeeLoading: boolean;
  getSkillEmployeeResponse: any;
  getSkillEmployeeLoading: boolean;
  createSkillEmployeeLoading: boolean;
  getCVEmployeeResponse: any;
  getCVEmployeeLoading: boolean;
  createCVEmployeeLoading: boolean;
  deleteCVEmployeeLoading: boolean;
  getEmployeeProjectsResponse: any;
  getEmployeeProjectsLoading: boolean;
  getEmployeeMonthBirthdayResponse: any;
  getEmployeeMonthBirthdayLoading: boolean;
  getEmployeeExpireResponse: any;
  getEmployeeExpireLoading: boolean;
  getEmployeeOnboardComingLoading: boolean;
  getEmployeeOnboardComingResponse: any;
  getEmployeeOnboardLoading: boolean;
  getEmployeeOnboardResponse: any;
  getEmployeeJobGradeLoading: boolean;
  getEmployeeJobGradeResponse: any;
  createEmployeeOnboardLoading: boolean;
  deleteEmployeeOnboardLoading: boolean;
  getDetailEmployeeOnboardLoading: boolean;
  getDetailEmployeeOnboardResponse: any;
  createNewEmployeeLoading: boolean;
  createNewEmployeeResponse: any;
  editEmployeeLoading: boolean;
  editEmployeeResponse: any;
  searchEmployeeResponse: any;
  searchEmployeeLoading: boolean;
  editDetailEmployeeLoading: boolean;
  updatePhoneEmployeeLoading: boolean;
  updatePhoneEmployeeResponse: any;
  updateEmployeeNotesLoading: boolean;
  getCertificatesLoading: boolean;
  getCertificatesResponse: any;
  addCertificatesLoading: boolean;
  addCertificatesResponse: any;
  deleteCertificatesLoading: boolean;
};

const initialState: stateType = {
  currentListEmployee: null,
  getAllEmployeeResponse: null,
  getAllEmployeeLoading: false,
  importEmployeeResponse: null,
  isImportEmployeeSucceeded: true,
  importEmployeeLoading: false,
  getDetailEmployeeLoading: false,
  getDetailEmployeeResponse: null,
  getCheEmployeeLoading: false,
  getCheEmployeeResponse: null,
  createCheEmployeeLoading: false,
  editCheEmployeeLoading: false,
  deleteCheEmployeeLoading: false,
  getSkillEmployeeResponse: null,
  getSkillEmployeeLoading: false,
  createSkillEmployeeLoading: false,
  getCVEmployeeResponse: null,
  getCVEmployeeLoading: false,
  createCVEmployeeLoading: false,
  deleteCVEmployeeLoading: false,
  getEmployeeProjectsResponse: null,
  getEmployeeProjectsLoading: false,
  getEmployeeMonthBirthdayResponse: null,
  getEmployeeMonthBirthdayLoading: false,
  getEmployeeExpireResponse: null,
  getEmployeeExpireLoading: false,
  getEmployeeOnboardComingLoading: false,
  getEmployeeOnboardComingResponse: null,
  getEmployeeOnboardLoading: false,
  getEmployeeOnboardResponse: null,
  getEmployeeJobGradeLoading: false,
  getEmployeeJobGradeResponse: null,
  createEmployeeOnboardLoading: false,
  deleteEmployeeOnboardLoading: false,
  getDetailEmployeeOnboardLoading: false,
  getDetailEmployeeOnboardResponse: null,
  createNewEmployeeLoading: false,
  createNewEmployeeResponse: null,
  editEmployeeLoading: false,
  editEmployeeResponse: null,
  searchEmployeeLoading: false,
  searchEmployeeResponse: null,
  editDetailEmployeeLoading: false,
  updatePhoneEmployeeLoading: false,
  updatePhoneEmployeeResponse: null,
  updateEmployeeNotesLoading: false,
  getCertificatesLoading: false,
  getCertificatesResponse: null,
  addCertificatesLoading: false,
  addCertificatesResponse: null,
  deleteCertificatesLoading: false,
};

const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    // get all employee
    getAllEmployeeRequest(state) {
      state.getAllEmployeeLoading = true;
    },
    getAllEmployeeSuccess(state, { payload }) {
      state.getAllEmployeeResponse = payload.data;
      state.getAllEmployeeLoading = false;
    },
    getAllEmployeeFailure(state) {
      state.getAllEmployeeLoading = false;
    },
    // import employee
    importEmployeeRequest(state, _action) {
      // state.importEmployeeLoading = true;
      // state.isImportEmployeeSucceeded = false;
      state.getAllEmployeeLoading = true;
    },
    importEmployeeSuccess(state, { payload }) {
      // state.isImportEmployeeSucceeded = payload.isSucceeded;
      // state.importEmployeeLoading = false;
      state.importEmployeeResponse = payload.data;
      state.getAllEmployeeLoading = false;
    },
    importEmployeeFailure(state) {
      state.importEmployeeLoading = false;
      // state.importEmployeeLoading = false;
      // state.importEmployeeResponse = payload.data;
    },
    // create new employee
    createNewEmployeeRequest(state, _action) {
      state.getAllEmployeeLoading = true;
    },
    createNewEmployeeSuccess(state, { payload }) {
      state.createNewEmployeeResponse = payload.data;
      state.getAllEmployeeLoading = false;
    },
    createNewEmployeeFailure(state) {
      state.createNewEmployeeLoading = false;
      state.getAllEmployeeLoading = false;
    },
    // edit employee request
    editEmployeeRequest(state, _action) {
      state.getAllEmployeeLoading = true;
    },
    editEmployeeSuccess(state, { payload }) {
      state.editEmployeeResponse = payload.data;
      state.getAllEmployeeLoading = false;
    },
    editEmployeeFailure(state) {
      state.editEmployeeLoading = false;
      state.getAllEmployeeLoading = false;
    },
    // get detail employee
    getDetailEmployeeRequest(state, _action) {
      state.getDetailEmployeeLoading = true;
    },
    getDetailEmployeeSuccess(state, { payload }) {
      state.getDetailEmployeeResponse = payload.data;
      state.getDetailEmployeeLoading = false;
    },
    getDetailEmployeeFailure(state) {
      state.getDetailEmployeeLoading = false;
    },
    // get che employee
    getCheEmployeeRequest(state, _action) {
      state.getCheEmployeeLoading = true;
    },
    getCheEmployeeSuccess(state, { payload }) {
      state.getCheEmployeeResponse = payload.data;
      state.getCheEmployeeLoading = false;
    },
    getCheEmployeeFailure(state) {
      state.getCheEmployeeLoading = false;
    },
    // create che employee
    createCheEmployeeFailure(state) {
      state.createCheEmployeeLoading = false;
    },
    createCheEmployeeRequest(state, _action) {
      state.createCheEmployeeLoading = true;
    },
    createCheEmployeeSuccess(state) {
      state.createCheEmployeeLoading = false;
    },
    // edit che employee
    editCheEmployeeRequest(state, _action) {
      state.editCheEmployeeLoading = true;
    },
    editCheEmployeeSuccess(state) {
      state.editCheEmployeeLoading = false;
    },
    editCheEmployeeFailure(state) {
      state.editCheEmployeeLoading = false;
    },
    // delete che employee
    deleteCheEmployeeRequest(state, _payload) {
      state.deleteCheEmployeeLoading = true;
    },
    deleteCheEmployeeSuccess(state) {
      state.deleteCheEmployeeLoading = false;
    },
    deleteCheEmployeeFailure(state) {
      state.deleteCheEmployeeLoading = false;
    },
    // get skill employee
    getSkillEmployeeRequest(state, _action) {
      state.getSkillEmployeeLoading = true;
    },
    getSkillEmployeeSuccess(state, { payload }) {
      state.getSkillEmployeeResponse = payload.data;
      state.getSkillEmployeeLoading = false;
    },
    getSkillEmployeeFailure(state) {
      state.getSkillEmployeeLoading = false;
    },
    // create skill
    createSkillEmployeeRequest(state, _action) {
      state.createSkillEmployeeLoading = true;
      state.getSkillEmployeeLoading = true;
    },
    createSkillEmployeeSuccess(state, { payload }) {
      state.getSkillEmployeeResponse = payload.data.skills;
      state.createSkillEmployeeLoading = false;
      state.getSkillEmployeeLoading = false;
    },
    createSkillEmployeeFailure(state) {
      state.createSkillEmployeeLoading = false;
      state.getSkillEmployeeLoading = false;
    },
    // get CV
    getCVEmployeeRequest(state, _action) {
      state.getCVEmployeeLoading = true;
    },
    getCVEmployeeSuccess(state, { payload }) {
      state.getCVEmployeeResponse = payload.data;
      state.getCVEmployeeLoading = false;
    },
    getCVEmployeeFailure(state) {
      state.getCVEmployeeLoading = false;
    },
    // create CV
    createCVEmployeeRequest(state, _action) {
      state.createCVEmployeeLoading = true;
    },
    createCVEmployeeSuccess(state) {
      state.createCVEmployeeLoading = false;
    },
    createCVEmployeeFailure(state) {
      state.createCVEmployeeLoading = false;
    },
    // Delete CV
    deleteCVEmployeeRequest(state, _action) {
      state.deleteCVEmployeeLoading = true;
    },
    deleteCVEmployeeSuccess(state) {
      state.deleteCVEmployeeLoading = false;
    },
    deleteCVEmployeeFailure(state) {
      state.deleteCVEmployeeLoading = false;
    },
    // get projects
    getEmployeeProjects(state, _action) {
      state.getEmployeeProjectsLoading = true;
    },
    getEmployeeProjectsSuccess(state, { payload }) {
      const projectAllocations = payload.data.rows.map(
        (proj: WorkTime, index: number) => {
          return {
            ...proj,
            color: COLORS[index % COLORS.length],
          };
        }
      );
      state.getEmployeeProjectsResponse = projectAllocations;
      state.getEmployeeProjectsLoading = false;
    },
    getEmployeeProjectsFailure(state) {
      state.getEmployeeProjectsLoading = false;
    },
    // clear projects
    clearEmployeeProjects(state) {
      state.getEmployeeProjectsLoading = false;
      state.getEmployeeProjectsResponse = null;
    },
    // Get all employee onboard
    getEmployeeOnboardRequest(state) {
      state.getEmployeeOnboardLoading = true;
    },
    getEmployeeOnboardSuccess(state, { payload }) {
      state.getEmployeeOnboardLoading = false;
      state.getEmployeeOnboardResponse = payload.data;

      // state.getEmployeeOnboardResponse = payload.data.sort(
      //   (empA: IEmployeeOnboard, empB: IEmployeeOnboard) => {
      //     if (moment(empA.onboardDate).isBefore(moment(empB.onboardDate))) {
      //       return -1;
      //     } else if (
      //       moment(empA.onboardDate).isAfter(moment(empB.onboardDate))
      //     ) {
      //       return 1;
      //     } else {
      //       return 0;
      //     }
      //   }
      // );
    },
    getEmployeeOnboardFailure(state) {
      state.getEmployeeOnboardLoading = false;
    },
    // Get employee job grade
    getEmployeeJobGradeRequest(state) {
      state.getEmployeeJobGradeLoading = true;
    },
    getEmployeeJobGradeSuccess(state, { payload }) {
      state.getEmployeeJobGradeLoading = false;
      state.getEmployeeJobGradeResponse = payload.data;
    },
    getEmployeeJobGradeFailure(state) {
      state.getEmployeeJobGradeLoading = false;
    },
    // Get detail employee onboard
    getDetailEmployeeOnboardRequest(state) {
      state.getDetailEmployeeOnboardLoading = true;
    },
    getDetailEmployeeOnboardSuccess(state, { payload }) {
      state.getDetailEmployeeOnboardLoading = false;
      state.getDetailEmployeeOnboardResponse = payload.data;
    },
    getDetailEmployeeOnboardFailure(state) {
      state.getDetailEmployeeOnboardLoading = false;
    },
    createEmployeeOnboardRequest(state, _action) {
      state.getEmployeeOnboardLoading = true;
    },
    createEmployeeOnboardSuccess(state, _action) {
      state.getEmployeeOnboardLoading = false;
    },
    createEmployeeOnboardFailure(state) {
      state.getEmployeeOnboardLoading = false;
    },
    deleteEmployeeOnboardRequest(state, _action) {
      state.getEmployeeOnboardLoading = true;
    },
    deleteEmployeeOnboardSuccess(state) {
      state.getEmployeeOnboardLoading = false;
    },
    deleteEmployeeOnboardFailure(state) {
      state.getEmployeeOnboardLoading = false;
    },
    // getEmployeeMonthBirthday
    getEmployeeMonthBirthdayRequest(state, _action) {
      state.getEmployeeMonthBirthdayLoading = true;
    },
    getEmployeeMonthBirthdaySuccess(state, { payload }) {
      state.getEmployeeMonthBirthdayResponse = payload.data;
      state.getEmployeeMonthBirthdayLoading = false;
    },
    getEmployeeMonthBirthdayFailure(state) {
      state.getEmployeeMonthBirthdayLoading = false;
    },
    // getEmployeeExpire
    getEmployeeExpireRequest(state, _action) {
      state.getEmployeeExpireLoading = true;
    },
    getEmployeeExpireSuccess(state, { payload }) {
      state.getEmployeeExpireResponse = payload.data;
      state.getEmployeeExpireLoading = false;
    },
    getEmployeeExpireFailure(state) {
      state.getEmployeeExpireLoading = false;
    },
    // get onboard coming
    getEmployeeOnboardComingRequest(state, _action) {
      state.getEmployeeOnboardComingLoading = true;
    },
    getEmployeeOnboardComingSuccess(state, { payload }) {
      state.getEmployeeOnboardComingResponse = payload.data.sort(
        (a: IEmployeeOnboard, b: IEmployeeOnboard) => {
          if (moment(a.onboardDate).isBefore(moment(b.onboardDate))) {
            return -1;
          }
          if (moment(a.onboardDate).isAfter(moment(b.onboardDate))) {
            return 1;
          }
          return 0;
        }
      );
      state.getEmployeeOnboardComingLoading = false;
    },
    getEmployeeOnboardComingFailure(state) {
      state.getEmployeeOnboardComingLoading = false;
    },
    editEmployeeOnboardRequest(_state, _action) {},
    editEmployeeOnboardSuccess(_state, _action) {},
    editEmployeeOnboardFailure(_state) {},
    searchEmployeeRequest(state, _action) {
      state.searchEmployeeLoading = true;
    },
    searchEmployeeSuccess(state, { payload }) {
      state.searchEmployeeResponse = payload.data;
      state.searchEmployeeLoading = false;
    },
    searchEmployeeFailure(state) {
      state.searchEmployeeLoading = false;
    },
    searchEmployeeReset(state) {
      state.searchEmployeeResponse = null;
    },
    editEmployeeAvatar(state, { payload }) {
      state.getDetailEmployeeResponse = {
        ...state.getDetailEmployeeResponse,
        avatar: payload,
      };
    },
    setCurrentListEmployee(state, { payload }) {
      state.currentListEmployee = payload;
    },

    // Update employee phone numer
    updatePhoneEmployeeRequest(state, _action) {
      state.updatePhoneEmployeeLoading = true;
    },
    updatePhoneEmployeeSuccess(state, { payload }) {
      state.getDetailEmployeeResponse = payload.data;
      state.updatePhoneEmployeeLoading = false;
    },
    updatePhoneEmployeeFailure(state) {
      state.updatePhoneEmployeeLoading = false;
    },
    resetDetailEmployee(state) {
      state.getDetailEmployeeResponse = null;
    },

    // Update employee notes
    updateEmployeeNotes(state, _action) {
      state.updateEmployeeNotesLoading = true;
    },
    updateEmployeeNotesSuccess(state) {
      state.updateEmployeeNotesLoading = false;
    },
    updateEmployeeNotesFailure(state) {
      state.updateEmployeeNotesLoading = false;
    },

    //Get all Certificates
    getCertificates(state, _payload) {
      state.getCertificatesLoading = true;
    },
    getCertificatesSuccess(state, { payload }) {
      state.getCertificatesLoading = false;
      state.getCertificatesResponse = payload?.data?.certificates;
    },
    getCertificatesFailure(state) {
      state.getCertificatesLoading = false;
    },

    //Add Certificates
    addCertificates(state, _payload) {
      state.addCertificatesLoading = true;
    },
    addCertificatesSuccess(state, { payload }) {
      state.addCertificatesResponse = payload;
      state.addCertificatesLoading = false;
    },
    addCertificatesFailure(state) {
      state.addCertificatesLoading = false;
    },

    //Delete Certificates
    deleteCertificates(state, _payload) {
      state.deleteCertificatesLoading = true;
    },
    deleteCertificatesSuccess(state) {
      state.deleteCertificatesLoading = false;
    },
    deleteCertificatesFailure(state) {
      state.deleteCertificatesLoading = false;
    },
  },
});

const { actions, reducer } = employeeSlice;

export { actions as employeeActions, reducer as employeeReducer };
