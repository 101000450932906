import { useAuth } from "@hooks/useAuth";
import { Spin } from "antd";
import "antd/dist/antd.min.css";
import { BrowserRouter as Router } from "react-router-dom";
import styled from "styled-components";
import { adminRoutes, publicRoutes } from "./config/routePath.config";
import LayoutAdmin from "./layouts/LayoutAdmin/LayoutAdmin";
import { renderRoutes } from "./routers";
import { checkTokenExpire } from "./utils/helpers";

export const LoadingWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function App() {
  const { loading, user } = useAuth();

  if (loading) {
    return (
      <LoadingWrapper>
        <Spin size="large" />
      </LoadingWrapper>
    );
  }

  return (
    <Router>
      {!checkTokenExpire() || (user && user.role) ? (
        <LayoutAdmin>{renderRoutes(adminRoutes)}</LayoutAdmin>
      ) : (
        renderRoutes(publicRoutes)
      )}
    </Router>
  );
}

export default App;
