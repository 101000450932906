import { AxiosResponse } from "axios";
import { axiosInstance } from "./../../config/axios.config";

export const workDiaryApi = {
  fetchAllEmployee: async (): Promise<AxiosResponse<any, any>> => {
    const URL = "Employee/get-all";
    const response = await axiosInstance.get(URL);
    return response;
  },
  createWorkDiary: async (payload: any): Promise<AxiosResponse<any, any>> => {
    const url = "MeetingNote";
    const response = (await axiosInstance.post)<any>(url, payload);
    return response;
  },
  getAllMeetingNote: async ({
    searchContent,
  }: {
    searchContent: string;
  }): Promise<AxiosResponse<any, any>> => {
    let url = "MeetingNote";
    if (searchContent) {
      url += `${searchContent}`;
    }
    const response = await axiosInstance.get<any>(url);
    return response;
  },
  deleteMeetingNote: async (payload: any): Promise<AxiosResponse<any, any>> => {
    const url = "MeetingNote";
    const response = await axiosInstance.delete<any>(url, { data: payload });
    return response;
  },

  getMeetingNote: async (payload: any): Promise<AxiosResponse<any, any>> => {
    const url = `MeetingNote/Get-Detail?id=${payload}`;
    const response = await axiosInstance.get<any>(url);
    return response;
  },

  editMeetingNote: async (payload: {
    id: string;
    values: any;
  }): Promise<AxiosResponse<any, any>> => {
    const url = `MeetingNote/${payload.id}`;
    const response = await axiosInstance.put<any>(url, payload.values);
    return response;
  },

  getListEmployee: async (): Promise<AxiosResponse<any, any>> => {
    const url = "User/get-list-user-employeee";
    const response = await axiosInstance.get<any>(url);
    return response;
  },
};
