import {
  showErrorNotification,
  showSuccessNotification,
} from "@utils/notification";
import { call, put, takeEvery } from "redux-saga/effects";
import { workDiaryApi } from "./workDiaryApi";
import { workDiaryActions } from "./workDiarySlice";

function* fetchAllEmployee(): Generator {
  try {
    const response = yield call(workDiaryApi.fetchAllEmployee);
    yield put(
      workDiaryActions.fetchAllEmployeeSucceeded(
        response as Record<string, any>
      )
    );
  } catch (e) {
    yield put(workDiaryActions.fetchAllEmployeeFailed());
  }
}

function* createWorkDiary({
  payload,
}: {
  payload: {
    value: string;
    searchParams: {
      searchContent: string;
      pageNumber: number;
      pageSize: number;
    };
  };
}): Generator {
  try {
    const response: any = yield call(
      workDiaryApi.createWorkDiary,
      payload.value
    );
    showSuccessNotification("Created  successful!");
    yield put(workDiaryActions.createWorkDiarySucceeded(response));
    yield put(
      workDiaryActions.getAllMeetingNote({
        searchContent: payload.searchParams?.searchContent,
      })
    );
  } catch (error) {
    showErrorNotification("Create meeting note failed!");
    yield put(workDiaryActions.createWorkDiaryFailed());
  }
}

export function* getAllMeetingNote(action: any): Generator {
  try {
    const response: any = yield call(workDiaryApi.getAllMeetingNote, {
      searchContent: action.payload?.searchContent,
    });
    if (response.isSucceeded && action.payload?.onSuccess) {
      action.payload.onSuccess(response);
    }
    yield put(workDiaryActions.getAllMeetingNoteSuccess(response));
  } catch (error) {
    showErrorNotification("Get all meeting notes failure!");
    yield put(workDiaryActions.getAllMeetingNoteFailure());
  }
}

export function* getMeetingNote(action: any): Generator {
  try {
    const response: any = yield call(
      workDiaryApi.getMeetingNote,
      action.payload?.value
    );
    yield put(workDiaryActions.getMeetingNoteSuccess(response));
    if (response.isSucceeded && action.payload?.onSuccess) {
      yield call(action.payload.onSuccess);
    }
  } catch (error) {
    showErrorNotification("Get meeting note failure!");
    yield put(workDiaryActions.getMeetingNoteFailure());
  }
}

function* deleteMeetingNote({
  payload,
}: {
  payload: {
    id: string;
    searchParams: {
      searchContent: string;
      pageNumber: number;
      pageSize: number;
    };
  };
}): Generator {
  try {
    const response: any = yield call(workDiaryApi.deleteMeetingNote, {
      id: payload.id,
    });

    if (response.isSucceeded) {
      yield put(workDiaryActions.deleteMeetingNoteSuccess());
      yield put(
        workDiaryActions.getAllMeetingNote({
          searchContent: payload.searchParams?.searchContent,
        })
      );
      showSuccessNotification("Delete meeting note successfully!");
    }
  } catch (error) {
    showErrorNotification("Delete meeting note failed!");
    yield put(workDiaryActions.deleteMeetingNoteFailure());
  }
}

export function* editMeetingNote({
  payload,
}: {
  payload: {
    id: string;
    values: any;
    searchParams: {
      searchContent: string;
      pageNumber: number;
      pageSize: number;
    };
  };
}): Generator {
  try {
    const response: any = yield call(workDiaryApi.editMeetingNote, {
      id: payload.id,
      values: payload.values,
    });

    yield put(workDiaryActions.editMeetingNoteSuccess(response));
    showSuccessNotification("Edit meeting note successfully");
    yield put(
      workDiaryActions.getAllMeetingNote({
        searchContent: payload.searchParams?.searchContent,
      })
    );
  } catch (error: any) {
    yield put(workDiaryActions.editMeetingNoteFailure());
    showErrorNotification("Edit meeting note failure!");
  }
}

function* getListEmployee(): Generator {
  try {
    const response = yield call(workDiaryApi.getListEmployee);
    yield put(workDiaryActions.getListEmployeeSuccess(response));
  } catch (e) {
    yield put(workDiaryActions.getListEmployeeFailure());
  }
}

export const workDiaryWatcher = [
  takeEvery(workDiaryActions.fetchAllEmployee, fetchAllEmployee),
  takeEvery(workDiaryActions.createWorkDiary, createWorkDiary),
  takeEvery(workDiaryActions.getAllMeetingNote, getAllMeetingNote),
  takeEvery(workDiaryActions.getMeetingNote, getMeetingNote),
  takeEvery(workDiaryActions.deleteMeetingNote, deleteMeetingNote),
  takeEvery(workDiaryActions.editMeetingNote, editMeetingNote),
  takeEvery(workDiaryActions.getListEmployee, getListEmployee),
];
