import { createSlice } from "@reduxjs/toolkit";

type stateType = { getAllRoleResponse: any; pagination: any };

const initialState: stateType = { getAllRoleResponse: null, pagination: null };

const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    getAllRoleRequest(state, _action) {
      return state;
    },
    getAllRoleSuccess(state, { payload }) {
      state.getAllRoleResponse = payload.data;
      state.pagination = payload.metaData;
    },
    getAllRoleFailure(state) {
      return state;
    },
  },
});

const { actions, reducer } = roleSlice;

export { actions as roleActions, reducer as roleReducer };
