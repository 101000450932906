import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { devicesApi } from "./devicesApi";
import { devicesActions } from "./devicesSlice";
import { ActionType } from "models";
import {
  showErrorNotification,
  showSuccessNotification,
} from "@utils/notification";

export function* getAllAssetsSagas(): Generator {
  try {
    const response = yield call(devicesApi.getAllAssets);
    yield put(devicesActions.getAllAssetsSuccess(response));
  } catch (error) {
    yield put(devicesActions.getAllAssetsFailure());
  }
}

export function* filterAssetSagas({ payload }: ActionType | any): Generator {
  try {
    const response: any = yield call(devicesApi.filter, payload);
    const { errors, isSucceeded } = response;
    if (isSucceeded) {
      yield put(devicesActions.filterAssetsSuccess(response));
    }
    if (errors) {
      yield put(devicesActions.filterAssetsFailure(errors));
    }
  } catch (e) {
    yield put(devicesActions.filterAssetsFailure(e));
  }
}

export function* getDetailAssetsSagas({ payload }: ActionType): Generator {
  try {
    const response = yield call(devicesApi.getDetailDevice, payload);
    yield put(devicesActions.getDetailAssetSuccess(response));
  } catch (error) {
    yield put(devicesActions.getDetailAssetFailure());
  }
}

export function* getByPICSagas({ payload }: ActionType): Generator {
  try {
    const response = yield call(devicesApi.getByPIC, payload.employeeId);
    yield put(devicesActions.getByPICSuccess(response));
  } catch (error) {
    yield put(devicesActions.getByPICFailure());
  }
}

export function* deleteAssetsSagas({ payload }: ActionType): Generator {
  try {
    const response: any = yield call(devicesApi.delete, payload);
    if (response?.isSucceeded) {
      yield put(devicesActions.deleteAssetSuccess());
      yield filterAssetSagas({});
      showSuccessNotification("Delete asset successfully");
    } else {
      yield put(devicesActions.deleteAssetFailure());
      showErrorNotification("Delete asset fail!!!");
    }
  } catch (error) {
    yield put(devicesActions.deleteAssetFailure());
    showErrorNotification("Delete asset fail!!!");
  }
}

export function* borrowAssetSagas({ payload }: ActionType): Generator {
  try {
    const response: any = yield call(devicesApi.borrow, payload);
    if (response?.isSucceeded) {
      yield put(devicesActions.borrowDeviceSuccess(response));
      showSuccessNotification("Return asset successfully");
    } else {
      yield put(devicesActions.borrowDeviceFailure(response?.errors));
    }
  } catch (error) {
    yield put(devicesActions.borrowDeviceFailure(error));
  }
}

export function* returnAssetSagas({ payload }: ActionType): Generator {
  try {
    const response: any = yield call(devicesApi.return, payload);
    if (response?.isSucceeded) {
      yield put(devicesActions.returnDeviceSuccess(response));
      showSuccessNotification("Return asset successfully");
    } else {
      yield put(devicesActions.returnDeviceFailure(response?.errors));
    }
  } catch (error) {
    yield put(devicesActions.returnDeviceFailure(error));
  }
}

export function* confirmAssetSagas({ payload }: ActionType): Generator {
  try {
    const response: any = yield call(devicesApi.confirm, payload);
    if (response?.isSucceeded) {
      yield put(devicesActions.confirmDeviceSuccess(response));
      showSuccessNotification("Confirm asset successfully");
    } else {
      yield put(devicesActions.confirmDeviceFailure(response?.errors));
    }
  } catch (error) {
    yield put(devicesActions.confirmDeviceFailure(error));
  }
}

export function* rejectAssetSagas({ payload }: ActionType): Generator {
  try {
    const response: any = yield call(devicesApi.reject, payload);
    if (response?.isSucceeded) {
      yield put(devicesActions.rejectDeviceSuccess(response));
      showSuccessNotification("Reject asset successfully");
    } else {
      yield put(devicesActions.rejectDeviceFailure(response?.errors));
    }
  } catch (error) {
    yield put(devicesActions.rejectDeviceFailure(error));
  }
}

export const devicesWatcher = [
  takeLatest(devicesActions.getAllAssetsRequest, getAllAssetsSagas),
  takeLatest(devicesActions.getDetailAssetRequest, getDetailAssetsSagas),
  takeLatest(devicesActions.getByPICRequest, getByPICSagas),
  takeLatest(devicesActions.deleteAssetRequest, deleteAssetsSagas),
  takeEvery(devicesActions.filterAssetsRequest, filterAssetSagas),
  takeLatest(devicesActions.borrowDeviceRequest, borrowAssetSagas),
  takeLatest(devicesActions.returnDeviceRequest, returnAssetSagas),
  takeLatest(devicesActions.confirmDeviceRequest, confirmAssetSagas),
  takeLatest(devicesActions.rejectDeviceRequest, rejectAssetSagas),
];
