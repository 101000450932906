import { useNavigate } from "react-router-dom";
import { BiMenu } from "react-icons/bi";
import ModalInfoAdmin from "./ModalInfoAdmin";
import { LogoBlack } from "@utils/media";
import {
  HeaderContainer,
  NavbarContainer,
  MobileToggleMenu,
  TopMenu,
  NavbarList,
  NavbarItem,
  NavbarLink,
} from "./HeaderStyled";
import { useAppSelector } from "@hooks/useAppSelector";
import { CheckExistRole } from "@utils/auth";
import { EmployeeRole } from "@utils/constants";

interface IHeaderAdminProps {
  setCollapsed: (collapsed: boolean) => void;
  collapsed: boolean;
}

function HeaderAdmin(props: IHeaderAdminProps) {
  const { setCollapsed, collapsed } = props;
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user.user);

  return (
    <HeaderContainer className="topbar">
      <NavbarContainer>
        <div className="logo-area">
          {CheckExistRole(user, EmployeeRole) ? null : (
            <MobileToggleMenu>
              <BiMenu onClick={() => setCollapsed(!collapsed)} />
            </MobileToggleMenu>
          )}
          <img
            src={LogoBlack}
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
            className="logo-dashboard"
            alt="logo"
          />
        </div>
        <TopMenu>
          <NavbarList>
            <NavbarItem>
              <NavbarLink className="nav-link nav-icon-padding">
                {/* <BiCategory /> */}
              </NavbarLink>
            </NavbarItem>
            <NavbarItem>
              <NavbarLink className="nav-link nav-position nav-icon-padding"></NavbarLink>
            </NavbarItem>
          </NavbarList>
        </TopMenu>
        <ModalInfoAdmin />
      </NavbarContainer>
    </HeaderContainer>
  );
}

export default HeaderAdmin;
