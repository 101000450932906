import { all } from "redux-saga/effects";
import { employeeWatcher } from "../features/employee/employeeSaga";
import { jobRankWatcher } from "../features/jobRank/jobRankSaga";
import { lineWatcher } from "../features/line/lineSaga";
import { projectWatcher } from "../features/project/projectSaga";
import { roleWatcher } from "../features/role/roleSaga";
import { authWatcher } from "../features/auth/authSaga";
import { uploadWatcher } from "../features/upload/uploadSaga";
import { userWatcher } from "../features/user/userSaga";
import { eventWatcher } from "../features/event/eventSaga";
import { resourceAllocationWatcher } from "../features/resource-allocation/resourceAllocation.saga";
import { eeWatcher } from "../features/ee/ee.saga";
import { odcAllowanceWatcher } from "../features/odc-allowance/odcAllowance.saga";
import { workDiaryWatcher } from "../features/work-diary/workDiarySaga";
import { customerWatcher } from "../features/customer/customerSaga";
import { chartWatcher } from "@features/chart/chartSaga";
import { accountWatcher } from "@features/account/accountSaga";
import { feedbackWatcher } from "@features/feedback/feedbackSaga";
import { certificatesWatcher } from "@features/certificates/certificatesSaga";
import { devicesWatcher } from "@features/devices/devicesSaga";
import { todoTaskWatcher } from "@features/todoTask/todoTaskSaga";

export function* rootSaga() {
  yield all([
    ...uploadWatcher,
    ...lineWatcher,
    ...roleWatcher,
    ...jobRankWatcher,
    ...projectWatcher,
    ...employeeWatcher,
    ...authWatcher,
    ...userWatcher,
    ...eventWatcher,
    ...resourceAllocationWatcher,
    ...eeWatcher,
    ...odcAllowanceWatcher,
    ...workDiaryWatcher,
    ...customerWatcher,
    ...chartWatcher,
    ...accountWatcher,
    ...feedbackWatcher,
    ...certificatesWatcher,
    ...devicesWatcher,
    ...todoTaskWatcher,
  ]);
}
