import { showErrorNotification } from "@utils/notification";
import { call, put, takeEvery } from "redux-saga/effects";
import { chartApi } from "./chartApi";
import { chartActions } from "./chartSlice";

type ActionType = {
  type: string;
  payload: any;
};

export function* getLineJuniorSenior({ payload }: ActionType): Generator {
  try {
    const response = yield call(chartApi.getNumberJuniorSenior, payload);
    yield put(chartActions.getLineJuniorSeniorSuccess(response));
    // TODO: handle callback | onSuccess
  } catch (error: any) {
    showErrorNotification("Get information juniors/seniors of line failure!");
    yield put(chartActions.getLineJuniorSeniorFailure());
    // TODO: handle callback | onFailure
  }
}

export function* getOnboardResign({ payload }: ActionType): Generator {
  try {
    const response = yield call(chartApi.getOnboardResign, payload);
    yield put(chartActions.getOnboardResignSuccess(response));
    // TODO: handle callback | onSuccess
  } catch (error) {
    showErrorNotification("Get information onboard/resign failure!");
    yield put(chartActions.getOnboardResignFailure());
    // TODO: handle callback | onFailure
  }
}

export function* getPDistribution(): Generator {
  try {
    const response = yield call(chartApi.getPDistribution);
    yield put(chartActions.getPDistributionSuccess(response));
    // TODO: handle callback | onSuccess
  } catch (error) {
    showErrorNotification("Get information failure!");
    yield put(chartActions.getPDistributionFailure());
    // TODO: handle callback | onFailure
  }
}

/*Watcher*/
export const chartWatcher = [
  takeEvery(chartActions.getLineJuniorSeniorRequest, getLineJuniorSenior),
  takeEvery(chartActions.getOnboardResignRequest, getOnboardResign),
  takeEvery(chartActions.getPDistributionRequest, getPDistribution),
];
