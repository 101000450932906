import { call, put, takeEvery } from "redux-saga/effects";

import { showErrorNotification } from "@utils/notification";
import { certificatesAction } from "./certificatesSlice";
import { certificatesApi } from "./certificatesApi";

interface ActionType {
  type: string;
  payload: any;
}

export function* getAllCertRequestWatcher({ payload }: ActionType): Generator {
  try {
    const response = yield call(certificatesApi.getAllCert, payload);
    yield put(certificatesAction.getAllCertSuccess(response));
  } catch (error) {
    showErrorNotification("Get User failure!");
    yield put(certificatesAction.getAllCertFailure());
  }
}

export const certificatesWatcher = [
  takeEvery(certificatesAction.getAllCertRequest, getAllCertRequestWatcher),
];
