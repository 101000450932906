import { API_ENDPOINT } from "config/apiEndpoint.config";
import { axiosInstance } from "config/axios.config";
import { IExportOdcAllowanceReportRequest } from "./odcAllowance.model";
import { saveAs } from "file-saver";

const odcAllowanceService = {
  exportReport: async (data: IExportOdcAllowanceReportRequest) => {
    const url = API_ENDPOINT.ODC_ALLOWANCE.EXPORT;
    let filename = `GST_ODC-allowance_${data.month?.replace("/", "-")}.xlsx`;

    const response: Blob = await axiosInstance.get(url, {
      params: {
        params: {
          month: data.month,
        },
      },
      responseType: "blob",
    });
    saveAs(response, filename);
  },
};

export { odcAllowanceService };
