import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { projectApi } from "./projectApi";
import { projectActions } from "./projectSlice";

type ActionType = {
  type: string;
  payload: any;
};

export function* getAllProjectWorker({ payload }: ActionType): Generator {
  try {
    const response = yield call(projectApi.getAllProject);
    yield put(projectActions.getAllProjectSuccess(response));
    // TODO: handle callback | onSuccess
  } catch (error) {
    yield put(projectActions.getAllProjectFailure());
    // TODO: handle callback | onFailure
  }
}

export function* loadProjectPlanWorker(): Generator {
  try {
    const response = yield call(projectApi.loadProjectPlan);
    yield put(projectActions.loadProjectPlanSuccess(response));
    // TODO: handle callback | onSuccess
  } catch (error) {
    yield put(projectActions.loadProjectPlanFailure());
    // TODO: handle callback | onFailure
  }
}

/*Watcher*/
export const projectWatcher = [
  takeEvery(projectActions.getAllProjectRequest, getAllProjectWorker),
  takeEvery(projectActions.loadProjectPlanRequest, loadProjectPlanWorker),
];
