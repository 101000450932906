import { createSlice } from "@reduxjs/toolkit";

type stateType = {
  uploadImageResponse: any;
  uploadLoading: any;
};

const initialState: stateType = {
  uploadImageResponse: null,
  uploadLoading: false,
};

const uploadSlice = createSlice({
  name: "upload",
  initialState,
  reducers: {
    importEmployeeRequest(state, _action) {
      return state;
    },
    importEmployeeSuccess(state) {
      return state;
    },
    importEmployeeFailure(state) {
      return state;
    },
    uploadImageRequest(state, _action) {
      state.uploadImageResponse = null;
    },
    uploadImageSuccess(state, { payload }) {
      state.uploadImageResponse = payload.data.url;
    },
    uploadImageFailure(state) {
      state.uploadImageResponse = null;
    },
    clearUploadImageResponse(state) {
      state.uploadImageResponse = null;
    },
    changeStatusUpload(state, action) {
      state.uploadLoading = action.payload;
    },
  },
});

const { actions, reducer } = uploadSlice;

export { actions as uploadActions, reducer as uploadReducer };
