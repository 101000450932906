import { createSlice } from "@reduxjs/toolkit";
import { IEvent } from "models";

type stateType = {
  getAllEventResponse: any;
  getAllEventLoading: boolean;
  getNewestEventResponse: any;
  getNewestEventLoading: boolean;
  getEventByIdResponse: any;
  getEventByIdLoading: boolean;
  pagination: any;
  isCreateEventSuccess: boolean;
  createEventLoading: boolean;
  isUpdateEventSuccess: boolean;
  updateEventLoading: boolean;
  isDeleteEventSuccess: boolean;
  deleteEventLoading: boolean;
};

const initialState: stateType = {
  getAllEventResponse: null,
  getAllEventLoading: false,
  getNewestEventResponse: null,
  getNewestEventLoading: false,
  getEventByIdResponse: null,
  getEventByIdLoading: false,
  pagination: null,
  isCreateEventSuccess: false,
  createEventLoading: false,
  isUpdateEventSuccess: false,
  updateEventLoading: false,
  isDeleteEventSuccess: false,
  deleteEventLoading: false,
};

const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    // get all event
    getAllEventRequest(state, _action) {
      state.getAllEventLoading = true;
    },
    getAllEventSuccess(state, { payload }) {
      state.getAllEventLoading = false;
      state.getAllEventResponse = payload.data.filter(
        (event: IEvent) => event.isDeleted !== true
      );
      state.pagination = payload.metaData;
    },
    getAllEventFailure(state) {
      state.getAllEventLoading = false;
      state.getAllEventResponse = [];
      state.pagination = null;
    },
    // get newest event
    getNewestEventRequest(state, _action) {
      state.getAllEventLoading = true;
    },
    getNewestEventSuccess(state, { payload }) {
      state.getNewestEventLoading = false;
      state.getNewestEventResponse = payload.data.filter(
        (event: IEvent) => event.isDeleted !== true
      );
    },
    getNewestEventFailure(state) {
      state.getNewestEventLoading = false;
      state.getNewestEventResponse = [];
    },
    // get event by id
    getEventByIdRequest(state, _action) {
      state.getEventByIdLoading = true;
    },
    getEventByIdSuccess(state, { payload }) {
      state.getEventByIdLoading = false;
      state.getEventByIdResponse = payload.data;
    },
    getEventByIdFailure(state) {
      state.getEventByIdLoading = false;
      state.getEventByIdResponse = null;
    },
    // create event
    createEventRequest(state, _action) {
      state.createEventLoading = true;
      state.isCreateEventSuccess = false;
    },
    createEventSuccess(state, _action) {
      state.createEventLoading = false;
      state.isCreateEventSuccess = true;
    },
    createEventFailure(state) {
      state.createEventLoading = false;
      state.isCreateEventSuccess = false;
    },
    // update event
    updateEventRequest(state, _action) {
      state.updateEventLoading = true;
      state.isUpdateEventSuccess = false;
    },
    updateEventSuccess(state, _action) {
      state.updateEventLoading = false;
      state.isUpdateEventSuccess = true;
    },
    updateEventFailure(state) {
      state.updateEventLoading = false;
      state.isUpdateEventSuccess = false;
    },
    // delete event
    deleteEventRequest(state, _action) {
      state.isDeleteEventSuccess = false;
      state.deleteEventLoading = true;
    },
    deleteEventSuccess(state, _action) {
      state.isDeleteEventSuccess = true;
      state.deleteEventLoading = false;
    },
    deleteEventFailure(state) {
      state.isDeleteEventSuccess = false;
      state.deleteEventLoading = false;
    },
  },
});

const { actions, reducer } = eventSlice;

export { actions as eventActions, reducer as eventReducer };
