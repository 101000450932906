import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { createBrowserHistory } from "history";
import "antd/dist/antd.min.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-quill/dist/quill.snow.css";

const history = createBrowserHistory();
const container = document.getElementById("root")!;
const root = createRoot(container);

if (root) {
  // 1. Set up the browser history with the updated location

  // (minus the # sign)

  const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];

  if (path) {
    history.replace(path);
  }

  root.render(
    // <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
    // </React.StrictMode>
  );
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
