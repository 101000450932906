import { AxiosResponse } from "axios";
import { IPayloadAction } from "models/base.model";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  IGetEEReportRequest,
  IGetEEReportResponse,
} from "./ee.model";
import { eeService } from "./ee.service";
import { eeActions } from "./ee.slice";

// Worker
export function* getEEWorker({
  payload,
}: IPayloadAction<IGetEEReportRequest>) {
  try {
    const response: AxiosResponse<IGetEEReportResponse> =
      yield call(
        eeService.getEEReport,
        payload.data
      );
    yield put(
      eeActions.getEEReportSuccess({
        data: response.data,
      })
    );
  } catch (error) {
    yield put(eeActions.getEEReportFailure());
  }
}

//Watcher
export const eeWatcher = [
  takeEvery(
    eeActions.calculateEERequest,
    getEEWorker
  ),
];
