import { createSlice } from "@reduxjs/toolkit";

type stateType = {
  accountData: any[];
  listEmployeeIdCreated: any[];
  pagination: any;
  isLoading: boolean;
  paramSearch: any;
};

const initialState: stateType = {
  accountData: [],
  listEmployeeIdCreated: [],
  paramSearch: {},
  isLoading: false,
  pagination: null,
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    reset(state) {
      state.accountData = [];
      state.paramSearch = {};
      state.pagination = null;
      state.isLoading = false;
    },
    getAccount(state, { payload }) {
      state.isLoading = true;
      state.paramSearch = payload;
      return state;
    },
    getAccountSuccess(state, { payload }) {
      state.accountData =
        payload.data?.map((item: any) => {
          return {
            ...item,
            claim:
              item?.claim?.map((itemClaim: any) => {
                return itemClaim?.value;
              }) || [],
          };
        }) || [];
      state.pagination = payload.metaData;
      state.isLoading = false;
    },
    getAccountFailure(state) {
      state.isLoading = false;
      return state;
    },
    getEmployeeIdCreated(state, _action) {
      state.isLoading = true;
    },
    getEmployeeIdCreatedSuccess(state, { payload }) {
      state.listEmployeeIdCreated = payload.data?.reduce(
        (currentList: any, item: any) => {
          if (item?.employeeId) {
            return [...currentList, item.employeeId];
          }
          return currentList;
        },
        []
      );
      state.isLoading = false;
    },
    getEmployeeIdCreatedFailure(state) {
      state.isLoading = false;
    },
    updatePaginationSearch(state, { payload }) {
      state.paramSearch = {
        ...state.paramSearch,
        pageNumber: payload?.pageNumber || 1,
        pageSize: payload?.pageSize || 10,
      };
    },
    lockUser(state) {
      state.isLoading = true;
    },
    lockUserSuccess(state) {
      state.isLoading = false;
    },
    lockUserFailure(state) {
      state.isLoading = false;
    },
  },
});

const { actions, reducer } = accountSlice;

export { actions as accountActions, reducer as accountReducer };
