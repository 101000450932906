import { userApi } from "@features/user/userApi";
import { TIME_EXPIRE_TOKEN } from "@utils/constants";
import axios, { AxiosRequestConfig } from "axios";
import moment from "moment";

export const BASE_URL = window?.__RUNTIME_CONFIG__?.REACT_APP_API_URL
  ? window.__RUNTIME_CONFIG__.REACT_APP_API_URL
  : process.env.REACT_APP_API_URL;

export const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 180000,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Expose-Headers": "x-pagination, Access-Token, Uid",
  },
});

const loginUrl = "login";
let isRefreshing = false;
let refreshTokenPromise: any = null;

axiosInstance.interceptors.request.use(
  async (request: AxiosRequestConfig) => {
    const accessToken = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");
    const expiration = localStorage.getItem("expiration");
    const isActive = localStorage.getItem("isActive");
    const isTokenExpired =
      moment(expiration).diff(moment(), "seconds") <= TIME_EXPIRE_TOKEN;
    const accessTokenRemainTime = moment(expiration).diff(moment(), "seconds");
    if (request.headers === undefined) {
      request.headers = {};
    }

    if (accessToken) {
      request.headers.Authorization = `Bearer ${accessToken}`;
    }

    if (request.url === "User/refresh-token") {
      return request;
    }

    if (isTokenExpired && !refreshTokenPromise && isActive) {
      refreshTokenPromise = userApi.refreshToken({
        token: accessToken!,
        refreshToken: refreshToken!,
      });
    }

    if (refreshTokenPromise) {
      if (accessTokenRemainTime <= 0) {
        // Need to finish the calling refresh token, then call other requests
        try {
          const response: any = await refreshTokenPromise;
          refreshTokenPromise = null;

          // set local storage variable
          localStorage.setItem("token", response.data.newToken);
          localStorage.setItem("refreshToken", response.data.refreshToken);
          localStorage.setItem("expiration", response.data.expiration);

          // set header with new access token
          request.headers.Authorization = `Bearer ${response.data.newToken}`;
        } catch (error) {
          // redirect to login page when calling refresh token was failed
          window.location.replace(`/${loginUrl}`);
          localStorage.removeItem("token");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("expiration");
        }
      } else {
        // Don't need to wait refresh token, other requests still be running, check it by "isRefreshing"
        try {
          if (!isRefreshing) {
            isRefreshing = true;
            const response: any = await refreshTokenPromise;
            // set local storage variable
            localStorage.setItem("token", response.data.newToken);
            localStorage.setItem("refreshToken", response.data.refreshToken);
            localStorage.setItem("expiration", response.data.expiration);

            // set header with new access token
            request.headers.Authorization = `Bearer ${response.data.newToken}`;

            refreshTokenPromise = null;
            isRefreshing = false;
          }
        } catch (error) {
          refreshTokenPromise = null;
          isRefreshing = false;
        }
      }
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  async (response) => {
    const pagination = response.headers["x-pagination"];
    if (pagination) {
      const parsed = JSON.parse(pagination);
      const metaData = {
        currentPage: parsed.CurrentPage,
        pageSize: parsed.PageSize,
        totalPages: parsed.TotalPages,
        totalCount: parsed.TotalCount,
        hasPreviousPage: parsed.HasPreviousPage,
        hasNext: parsed.HasNext,
      };
      response.data = {
        metaData,
        ...response.data,
      };
    }
    return response.data;
  },
  async (error) => {
    const { status } = error.response;
    switch (status) {
      case 400:
        // TODO: Define what to do on 400
        break;
      case 401:
        // TODO: Define what to do on 401

        break;
      case 403:
        // TODO: Define what to do on 403
        break;
      case 408:
        // TODO: Define what to do on 408
        break;
      default:
        break;
    }
    return Promise.reject(error);
  }
);
