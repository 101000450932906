import styled from "styled-components";

interface LayoutAdminWrapperProps {
  collapsed: boolean;
}

const LayoutAdminWrapper = styled.div<LayoutAdminWrapperProps>`
  min-height: 100vh;
  &&& {
    .main-content {
      width: 100%;
      min-height: calc(100vh - 60px);
      display: flex;
    }
    .header {
      height: 60px;
      background-color: #333;
    }
    .outlet-content {
      min-height: 70vh;
    }
    .content {
      width: 100%;
      display: flex;
      flex-direction: column;
      transition: ease 0.5s;

      padding-left: ${(props: LayoutAdminWrapperProps) =>
        props.collapsed ? "0px" : "var(--sidebar-width)"};

      .outlet-content {
        flex-grow: 1;
      }
    }
  }
  .outlet-content {
    background-color: #f7f7ff;
  }

  @media only screen and (max-width: 768px) {
    .content {
      padding-left: 0 !important;
    }
  }
`;

export const OnboardListContainer = styled.div`
  max-height: 500px;
  overflow: auto;

  .employee {
    display: flex;
    align-items: flex-start;
    gap: 1rem;

    &:not(:first-child) {
      margin-top: 1rem;
    }

    .avatar {
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
    }
    .info {
      font-size: 1rem;
      .fullname {
        margin: 0;
        font-weight: 500;
        text-transform: capitalize;
      }
      .date {
        margin: 0;
      }
    }
  }
`;

export default LayoutAdminWrapper;
