import styled from "styled-components";

interface SidebarWrapperProps {
  width?: number | string;
}

const SidebarWrapper = styled.div<SidebarWrapperProps>`
  padding-top: 1rem;
  background-color: #fff;
  position: fixed;
  top: var(--header-height);
  left: 0;
  bottom: 0;
  width: var(--sidebar-width);
  transition: all ease 0.5s;
  z-index: 9;

  & * {
    transition: ease 0.5s;
  }

  &.sidebar-custom.collapsed {
    transform: translateX(calc(-10px - var(--sidebar-width)));
  }

  &&& {
    .sidebar-custom {
      overflow-x: hidden;
      overflow-y: scroll;
      position: sticky;
      width: 16rem;
      height: 100%;
      flex-direction: column;
      &.collapsed {
        width: 0;
      }
      &::-webkit-scrollbar {
        display: none;
        width: 3px;
        background: white;
      }
      &:hover {
        &::-webkit-scrollbar {
          display: inline-block;
        }
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 2px lightblue;
      }
      &::-webkit-scrollbar-thumb {
        background: #ff9999;
      }
    }
    /* .ant-menu-root {
      width: calc(16rem - 16px);
      margin: 0 8px;
    } */
    .ant-menu-submenu,
    .ant-menu-item {
      & * {
        transition: all 0s;
      }
    }
    .ant-menu {
      background-color: transparent;
    }
    .ant-menu-inline,
    .ant-menu-vertical,
    .ant-menu-vertical-left {
      border-right: 0px solid transparent;
    }
    .ant-menu-submenu-active > div,
    .ant-menu-submenu-open > div {
      color: #1890ff;
      background-color: rgba(13, 110, 253, 0.12);
      transition: all 0s;
      & svg {
        fill: #1890ff;
      }
      & i {
        color: #1890ff;
      }
    }
    .ant-menu-sub {
      border: 1px solid #ededed;
      margin-top: -4px;
      border-top-color: transparent;
      background-color: transparent;
    }
    &&&& .ant-menu-item {
      padding-left: 0;
    }
    .admin_arrow {
      width: 12px;
      height: 12px;
    }
    .ant-menu-light .ant-menu-item:hover {
      color: #008cff;
      text-decoration: none;
      background: rgb(13 110 253 / 0.12);
    }
    .ant-menu-light .ant-menu-item:hover .symbol-icon {
      fill: #008cff;
    }
    @media (max-width: 900px) {
      .sidebar-custom {
        position: fixed;
        z-index: 999999;
        background-color: #fff;
      }
    }
  }
`;

export default SidebarWrapper;
