import {
  HomeOutlined,
  BarChartOutlined,
} from "@ant-design/icons";
import { ReactComponent as ArrowRight } from "@assets/svg/arrow-right.svg";
import { ReactComponent as UserIcon } from "@assets/svg/users.svg";
import { useAppSelector } from "@config/hooks";
import { useWindowSize } from "@hooks/useWindowSize/useWindowSize";
import { Menu, Space } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import {
  AiOutlineAudit,
  AiOutlineDollar,
  AiOutlineProject,
  AiOutlineDiff,
  AiOutlineCalendar,
} from "react-icons/ai";
import { MdAdminPanelSettings } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import SidebarWrapper from "./SidebarStyled";
import { FaCertificate, FaUserTie } from "react-icons/fa";
import { MENU } from "@layouts/LayoutAdmin/components/Sidebar/constant";
import { CheckExistRole } from "@utils/auth";
import { AdminHRRole, BulRole, EmployeeRole } from "@utils/constants";
import { ROUTE_NAMES } from "@utils/constants/routeNames.constant";
import { RiDeviceFill } from "react-icons/ri";

function getItem(
  label: React.ReactNode,
  key: string,
  icon: React.ReactNode | null = null,
  children: ItemType[] | undefined = undefined
): ItemType {
  return {
    label,
    key,
    icon,
    children,
  };
}

interface SideBarProps {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
}

const SideBar = ({ collapsed, setCollapsed }: SideBarProps) => {
  const location = useLocation();
  const { width } = useWindowSize();
  const { user } = useAppSelector((state) => state.user);

  // Item side bar
  const bulItems: ItemType[] | null = [
    user?.role === BulRole ||
    ((user?.claim || []).includes(MENU.DASHBOARD) &&
      !CheckExistRole(user, EmployeeRole))
      ? getItem(
          <Link to="/">
            <span>Dashboard</span>
          </Link>,
          "/",
          <HomeOutlined width={16} height={16} className="symbol-icon" />
        )
      : null,

    user?.role === BulRole ||
    ((user?.claim || []).includes(MENU.KPI) &&
      !CheckExistRole(user, EmployeeRole))
      ? getItem(
          <Link to="/kpi-page">
            <span>KPI</span>
          </Link>,
          "/kpi-page",
          <BarChartOutlined width={16} height={16} className="symbol-icon" />
        )
      : null,

    user?.role === BulRole ||
    ((user?.claim || []).includes(MENU.EMPLOYEE_MANAGEMENT) &&
      !CheckExistRole(user, EmployeeRole))
      ? getItem(
          <Link to="/employees">
            <span>Employee Management</span>
          </Link>,
          "/employees",
          <UserIcon width={16} height={16} className="symbol-icon" />
        )
      : null,
    user?.role === BulRole ||
    ((user?.claim || []).includes(MENU.RESOURCE_PLANNING) &&
      !CheckExistRole(user, EmployeeRole))
      ? getItem(
          <Link to="/planning">
            <span>Resource Planning</span>
          </Link>,
          "/planning",
          <AiOutlineCalendar width={16} height={16} className="symbol-icon" />
        )
      : null,
    user?.role === BulRole ||
    ((user?.claim || []).includes(MENU.RESOURCE_ALLOCATION) &&
      !CheckExistRole(user, EmployeeRole))
      ? getItem(
          <Link to="/allocation">
            <span>Resource Allocation</span>
          </Link>,
          "/allocation",
          <AiOutlineAudit width={16} height={16} className="symbol-icon" />
        )
      : null,

      user?.role === BulRole ||
      ((user?.claim || []).includes(MENU.PROJECT_PLAN) &&
        !CheckExistRole(user, EmployeeRole))
        ? getItem(
            <Link to="/project-plan">
              <span>Project Plan</span>
            </Link>,
            "/project-plan",
            <BarChartOutlined width={16} height={16} className="symbol-icon" />
          )
        : null,
    // user?.role === BulRole ||
    // ((user?.claim || []).includes(MENU.EE) &&
    //   !CheckExistRole(user, EmployeeRole))
    //   ? getItem(
    //       <Link to="/ee">
    //         <span>EE</span>
    //       </Link>,
    //       "/ee",
    //       <AiOutlineProject width={16} height={16} className="symbol-icon" />
    //     )
    //   : null,
    // user?.role === BulRole ||
    // ((user?.claim || []).includes(MENU.ODC_ALLOWANCE) &&
    //   !CheckExistRole(user, EmployeeRole))
    //   ? getItem(
    //       <Link to="/odcallowance">
    //         <span>ODC Allowance</span>
    //       </Link>,
    //       "/odcallowance",
    //       <AiOutlineDollar width={16} height={16} className="symbol-icon" />
    //     )
    //   : null,

    user?.role === BulRole ||
    ((user?.claim || []).includes(MENU.CUSTOMER_MANAGEMENT) &&
      !CheckExistRole(user, EmployeeRole))
      ? getItem(
          <Link to="/customer">
            <span>Customer Management</span>
          </Link>,
          "/customer",
          <FaUserTie width={16} height={16} className="symbol-icon" />
        )
      : null,

    user?.role === BulRole ||
    ((user?.claim || []).includes(MENU.MEETING_NOTE) &&
      !CheckExistRole(user, EmployeeRole))
      ? getItem(
          <Link to="/work-diary">
            <span>Meeting Note</span>
          </Link>,
          "/work-diary",
          <AiOutlineDiff width={16} height={16} className="symbol-icon" />
        )
      : null,

    user?.role === BulRole ||
    ((user?.claim || []).includes(MENU.CERTIFICATE) &&
      !CheckExistRole(user, EmployeeRole))
      ? getItem(
          <Link to="/certificates">
            <span>Certificates Management</span>
          </Link>,
          "/certificates",
          <FaCertificate width={16} height={16} className="symbol-icon" />
        )
      : null,

    user?.role === BulRole ||
    ((user?.claim || []).includes(MENU.DEVICE) &&
      !CheckExistRole(user, EmployeeRole))
      ? getItem(
          <Link to={ROUTE_NAMES.DEVICE.URL}>
            <span>Devices Management</span>
          </Link>,
          ROUTE_NAMES.DEVICE.URL,
          <RiDeviceFill width={16} height={16} className="symbol-icon" />
        )
      : null,
  ];

  const adminItems: ItemType[] = CheckExistRole(user, AdminHRRole)
    ? [
        getItem(
          <span>Admin Site</span>,
          "",
          <MdAdminPanelSettings
            width={16}
            height={16}
            className="symbol-icon"
          />,
          [
            getItem(
              <Link to="/admin/create-onboard">
                <Space>
                  <ArrowRight className="admin_arrow" />
                  <span>Create Onboard</span>
                </Space>
              </Link>,
              "/admin/create-onboard"
            ),
            getItem(
              <Link to="/admin/list-onboard">
                <Space>
                  <ArrowRight className="admin_arrow" />
                  <span>List Onboard</span>
                </Space>
              </Link>,
              "/admin/list-onboard"
            ),
            getItem(
              <Link to="/events/create">
                <Space>
                  <ArrowRight className="admin_arrow" />
                  <span>Create Event</span>
                </Space>
              </Link>,
              "/admin/create-event"
            ),
            getItem(
              <Link to="/events">
                <Space>
                  <ArrowRight className="admin_arrow" />
                  <span>List Event</span>
                </Space>
              </Link>,
              "/admin/list-event"
            ),
            getItem(
              <Link to="/admin/list-employee">
                <Space>
                  <ArrowRight className="admin_arrow" />
                  <span>List Employee</span>
                </Space>
              </Link>,
              "/admin/list-employee"
            ),
          ]
        ),
      ]
    : [];
  // --End Item side bar---

  const handleClickItem = () => {
    if (width <= 768) {
      setCollapsed(true);
    }
  };

  return (
    <SidebarWrapper
      width={width}
      className={collapsed ? "sidebar-custom collapsed" : "sidebar-custom"}
    >
      <Menu
        defaultSelectedKeys={["/"]}
        selectedKeys={[location.pathname]}
        mode="inline"
        items={[...bulItems, ...adminItems]}
        onClick={handleClickItem}
      />
    </SidebarWrapper>
  );
};

export default SideBar;
