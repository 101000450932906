import { axiosInstance } from "@config/axios.config";
import { AxiosResponse } from "axios";

const feedbackApi = {
  getAllUser: async (): Promise<AxiosResponse<any, any>> => {
    const url = "User/user-options";
    const response: AxiosResponse = await axiosInstance.get<any>(url);
    return response;
  },
  sendMessage: async (payload: any): Promise<AxiosResponse<any, any>> => {
    const url = "message/send-message";
    const response: AxiosResponse = await axiosInstance.post<any>(url, payload);
    return response;
  },
  getAllMessage: async (): Promise<AxiosResponse<any, any>> => {
    const url = "User/get-history-messages";
    const response: AxiosResponse = await axiosInstance.get<any>(url);
    return response;
  },
};

export { feedbackApi };
