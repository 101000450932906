import { AxiosResponse } from "axios";
import { axiosInstance } from "../../config/axios.config";
import { IBaseResponse } from "models/base.model";
import { API_ENDPOINT } from "@config/apiEndpoint.config";
import saveAs from "file-saver";
import moment from "moment";

const projectApi = {
  getAllProject: async (): Promise<AxiosResponse<any, any>> => {
    const response = await axiosInstance.get(API_ENDPOINT.PROJECT.BASE_URL);
    return response;
  },
  import: async (payload: any): Promise<AxiosResponse<IBaseResponse<any>>> => {
    const bodyFormData = new FormData();
    bodyFormData.append("file", payload);
    const response = await axiosInstance.post<any>(
      API_ENDPOINT.PROJECT.IMPORT,
      bodyFormData
    );
    return response;
  },
  export: async (data: any) => {
    const url = API_ENDPOINT.PROJECT.EXPORT;
    let filename = `HRM_All_prjects_${moment().format("YYYYMMDDHHmmss")}.xlsx`;

    const response: Blob = await axiosInstance.get(url, {
      params: {
        params: data,
      },
      responseType: "blob",
    });
    saveAs(response, filename);
  },
  loadProjectPlan: async (): Promise<AxiosResponse<any, any>> => {
    const response = await axiosInstance.get(API_ENDPOINT.PROJECT.LOAD_PROJECT_PLAN);
    return response;
  },
};

export { projectApi };
