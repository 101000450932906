import { call, put, takeEvery } from "redux-saga/effects";
import { roleApi } from "./roleApi";
import { roleActions } from "./roleSlice";

type ActionType = {
  type: string;
  payload: any;
};

export function* getAllRoleWorker({ payload }: ActionType): Generator {
  try {
    const response = yield call(roleApi.getAllRole, payload);
    yield put(roleActions.getAllRoleSuccess(response));
    // TODO: handle callback | onSuccess
  } catch (error) {
    yield put(roleActions.getAllRoleFailure());
    // TODO: handle callback | onFailure
  }
}

/*Watcher*/
export const roleWatcher = [
  takeEvery(roleActions.getAllRoleRequest, getAllRoleWorker),
];
