import styled from "styled-components";

export const Wrapper = styled.div`
  &&& {
    .ant-dropdown-menu-item:last-child {
      clear: both;
      margin: 0;
      padding: 5px 12px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      cursor: pointer;
      transition: all 0.3s;
    }
  }
`;

export const InfoLogout = styled.span`
  display: flex;
  align-items: center;
  width: 100%;
  a {
    width: 100%;
    color: rgba(0, 0, 0, 0.85);
  }

  .span-logout {
    margin-left: 8px;
  }
`;
