import { axiosInstance } from "@config/axios.config";
import { AxiosResponse } from "axios"
import { IBaseResponse } from "models/base.model";
import { ICustomerRequest } from "models/customer";

const baseUrl = "customer";

const customerApi = {
  getAllCustomer: async ({ search, pageNumber, pageSize }: { search: string, pageNumber: number, pageSize: number }): Promise<AxiosResponse<any, any>> => {
    let url = baseUrl;
    if (search) {
      url += `${search}&PageNumber=${pageNumber}&PageSize=${pageSize}`
    }
    else {
      url += `?PageNumber=${pageNumber}&PageSize=${pageSize}`
    }
    const response = await axiosInstance.get<any>(url)
    return response;
  },
  getCustomerById: async (id: string): Promise<AxiosResponse<any, any>> => {
    const url = `${baseUrl}/GetById/${id}`;
    const response = await axiosInstance.get<any>(url);
    return response;
  },
  addCustomer: async (
    payload: ICustomerRequest
  ): Promise<AxiosResponse<any, any>> => {
    const url = baseUrl;
    const response = await axiosInstance.post<any>(url, payload);
    return response;
  },
  editCustomer: async (
    payload: { id: string, values: ICustomerRequest }
  ): Promise<AxiosResponse<any, any>> => {
    const url = `${baseUrl}?id=${payload.id}`;
    const response = await axiosInstance.put<any>(url, payload.values);
    return response;
  },
  deleteCustomer: async (
    id: string
  ): Promise<AxiosResponse<any, any>> => {
    const url = `${baseUrl}?id=${id}`;
    const response = await axiosInstance.delete<any>(url);
    return response;
  },
  deleteFileCustomer: async (
    url: string
  ): Promise<AxiosResponse<IBaseResponse<[]>>> => {
    const urlString = `CustomerFile?url=${url}`;
    const response = await axiosInstance.delete<any>(urlString);
    return response;
  },
}

export { customerApi }