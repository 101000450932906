import { AxiosResponse } from "axios";
import { axiosInstance } from "../../config/axios.config";

type BaseResponse = {
  data: any;
  errors: any;
  isSucceeded: boolean;
};
type actionType = {
  type: string;
  _payload: any;
};

const eventApi = {
  getAllEvent: async ({
    _payload,
  }: actionType): Promise<AxiosResponse<BaseResponse, any>> => {
    const url = "Event/get-all-event";
    const response = (await axiosInstance.get)<BaseResponse>(url);
    return response;
  },

  getEventById: async (
    payload: any
  ): Promise<AxiosResponse<BaseResponse, any>> => {
    const url = "Event/get-by-id";
    const config = {
      params: {
        id: payload.id,
      },
    };
    const response = (await axiosInstance.get)<BaseResponse>(url, config);
    return response;
  },

  createEvent: async (payload: any): Promise<AxiosResponse<any, any>> => {
    const url = "Event";
    const { newEvent } = payload;
    const response = (await axiosInstance.post)<any>(url, newEvent);
    return response;
  },

  updateEvent: async (payload: any): Promise<AxiosResponse<any, any>> => {
    const url = "Event";
    const { event } = payload;
    const config = {
      params: { id: event.id },
    };
    const response = (await axiosInstance.put)<any>(url, event, config);
    return response;
  },

  deleteEvent: async (payload: any): Promise<AxiosResponse<any, any>> => {
    const url = "Event";
    const config = {
      params: { id: payload.id },
    };

    const response = (await axiosInstance.delete)<any>(url, config);
    return response;
  },

  getNewestEvents: async (payload: any): Promise<AxiosResponse<any, any>> => {
    const url = "/SortFilter/get-newest-event";
    let { day } = payload;

    const config = {
      params: {
        day: day || 30,
      },
    };
    const response = (await axiosInstance.get)<any>(url, config);
    return response;
  },
};

export { eventApi };
