import { AxiosResponse } from "axios";
import { axiosInstance } from "../../config/axios.config";

type actionType = {
  type: string;
  _payload: any;
};

const lineApi = {
  getAllLine: async ({
    _payload,
  }: actionType): Promise<AxiosResponse<any, any>> => {
    const url = "Line";
    const response = (await axiosInstance.get)<any>(url);
    return response;
  },
  getAllLineNotUseSaga: async (): Promise<AxiosResponse<any, any>> => {
    const url = "Line";
    const response = (await axiosInstance.get)<any>(url);
    return response;
  },
};

export { lineApi };
