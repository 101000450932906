import { AxiosResponse } from "axios";
import moment from "moment";
import { axiosInstance } from "../../config/axios.config";
import { IBaseResponse } from "models/base.model";
import { IEmployee, IEmployeeNotesRequest } from "models";
import { IQueryUserPermission } from "@pages/UserPermission/components/ModalUserPermission/ModalUserPermission";

// type BaseResponse = {
//   data: any;
//   errors: any;
//   isSucceeded: boolean;
// };

const employeeApi = {
  getAllEmployee: async (): Promise<AxiosResponse<any, any>> => {
    const url = "employee/get-all";
    const response = (await axiosInstance.get)<any>(url);

    return response;
  },
  importEmployee: async (payload: any): Promise<AxiosResponse<any, any>> => {
    const url = "employee";
    const response = (await axiosInstance.post)<any>(url, payload);

    return response;
  },
  getDetailEmployee: async (payload: any): Promise<AxiosResponse<any, any>> => {
    const url = `employee/getById/${payload}`;
    const response = (await axiosInstance.get)<any>(url);

    return response;
  },
  getCheEmployee: async (payload: any): Promise<AxiosResponse<any, any>> => {
    const url = `CHE/GetCheByEmp/${payload}`;
    const response = (await axiosInstance.get)<any>(url);

    return response;
  },
  createCheEmployee: async (payload: any): Promise<AxiosResponse<any, any>> => {
    const url = `CHE`;
    const response = (await axiosInstance.post)<any>(url, payload);

    return response;
  },
  editCheEmployee: async (payload: any): Promise<AxiosResponse<any, any>> => {
    const url = `CHE?id=${payload.id}`;
    const response = (await axiosInstance.put)<any>(url, {
      description: payload.description,
      userId: payload.userId,
      employeeId: payload.employeeId,
    });
    return response;
  },
  deleteCheEmployee: async (payload: any): Promise<AxiosResponse<any, any>> => {
    const url = `CHE?id=${payload.id}&&userId=${payload.userId}`;
    const response = (await axiosInstance.delete)<any>(url);
    return response;
  },
  getSkillEmployee: async (payload: any): Promise<AxiosResponse<any, any>> => {
    const url = `Skill?empId=${payload}`;
    const response = (await axiosInstance.get)<any>(url);

    return response;
  },
  createSkillEmployee: async (
    payload: any
  ): Promise<AxiosResponse<any, any>> => {
    const url = `Skill?empId=${payload.id}`;
    const response = (await axiosInstance.post)<any>(url, payload.skills);

    return response;
  },
  getCVEmployee: async (payload: any): Promise<AxiosResponse<any, any>> => {
    const url = `CV/GetCVByEmp/${payload}`;
    const response = (await axiosInstance.get)<any>(url);

    return response;
  },
  createCVEmployee: async (payload: any): Promise<AxiosResponse<any, any>> => {
    const url = `CV`;
    const response = (await axiosInstance.post)<any>(url, payload);

    return response;
  },
  deleteCVEmployee: async (payload: any): Promise<AxiosResponse<any, any>> => {
    const url = `CV/Delete/${payload}`;
    const response = (await axiosInstance.delete)<any>(url);

    return response;
  },
  getEmployeeProjects: async (
    payload: any
  ): Promise<AxiosResponse<any, any>> => {
    const url = "Jira/user-calendar-report";
    const currentYear = moment().year();
    const yearQuery = payload.year || currentYear;
    const data = {
      projectCode: "",
      account: payload.account || "",
      beginAssignment: `Jan/${yearQuery}`,
      endAssignment: `Dec/${yearQuery}`,
      currentPage: 1,
      pageSize: 1000,
    };
    const response = (await axiosInstance.post)<any>(url, data);

    return response;
  },

  getEmployeeByMonthBirthday: async (
    payload: any
  ): Promise<AxiosResponse<any, any>> => {
    const url = "/SortFilter/get-dob-employee";
    let { month } = payload;
    const config = {
      params: {
        month: month || moment().month() + 1,
      },
    };
    const response = (await axiosInstance.get)<any>(url, config);
    return response;
  },

  getEmployeeByExpire: async (
    payload: any
  ): Promise<AxiosResponse<any, any>> => {
    const url = "SortFilter/get-expire-date-employee";
    let { day } = payload;

    const config = {
      params: {
        day: day || 30,
      },
    };
    const response = (await axiosInstance.get)<any>(url, config);
    return response;
  },

  getEmployeeOnboardComing: async (
    payload: any
  ): Promise<AxiosResponse<any, any>> => {
    const url = "/SortFilter/get-on-board-date";
    let { day } = payload;

    const config = {
      params: {
        day: day || 7,
      },
    };
    const response = (await axiosInstance.get)<any>(url, config);
    return response;
  },
  getEmployeeJobGrade: async (): Promise<AxiosResponse<any, any>> => {
    const url = "employee/get-employee-jobgrade";
    const response = (await axiosInstance.get)<any>(url);
    return response;
  },
  getEmployeeOnboard: async (): Promise<AxiosResponse<any, any>> => {
    const url = "EmployeeOnBoard/get-all-employee-on-board";
    const response = (await axiosInstance.get)<any>(url);
    return response;
  },
  createEmployeeOnboard: async (
    payload: any
  ): Promise<AxiosResponse<any, any>> => {
    const url = "EmployeeOnBoard";
    const response = (await axiosInstance.post)<any>(url, payload);
    return response;
  },
  deleteEmployeeOnboard: async (
    payload: any
  ): Promise<AxiosResponse<any, any>> => {
    const url = `EmployeeOnBoard?empId=${payload}`;
    const response = (await axiosInstance.delete)<any>(url);
    return response;
  },
  editEmployeeOnboard: async (
    payload: any
  ): Promise<AxiosResponse<any, any>> => {
    const url = `EmployeeOnBoard?empId=${payload.empId}`;
    const response = (await axiosInstance.put)<any>(url, payload);
    return response;
  },
  searchEmployeeByAccount: async (
    payload: IQueryUserPermission
  ): Promise<AxiosResponse<IBaseResponse<IEmployee[]>>> => {
    const url = `Employee/FilterEmployee`;
    const response = await axiosInstance.get<IBaseResponse<IEmployee[]>>(url, {
      params: payload,
    });
    return response;
  },
  updatePhoneEmployee: async (
    payload: any
  ): Promise<AxiosResponse<any, any>> => {
    const url = `Employee/${payload.employeeId}?phone=${payload.phone}`;
    const response = (await axiosInstance.put)<any>(url, payload);
    return response;
  },
  importEmployees: async (
    payload: any
  ): Promise<AxiosResponse<IBaseResponse<any>>> => {
    const bodyFormData = new FormData();
    bodyFormData.append("formFile", payload);
    const url = `Employee/import-employee`;
    const response = await axiosInstance.post<any>(url, bodyFormData);
    return response;
  },
  updateEmployeeNotes: async (
    payload: IEmployeeNotesRequest
  ): Promise<AxiosResponse<any, any>> => {
    const url = `Employee/${payload.employeeId}/add-notes`;
    const response = (await axiosInstance.post)<any>(url, payload);
    return response;
  },
  getCertificates: async (payload: any): Promise<AxiosResponse<any, any>> => {
    const url = `Certificate/GetCertByEmp/${payload}`;
    const response = await axiosInstance.get<any>(url);
    return response;
  },
  addCertificates: async (payload: any): Promise<AxiosResponse<any, any>> => {
    const url = `Certificate`;
    const response = await axiosInstance.post<any>(url, payload);
    return response;
  },
  deleteCertificates: async (
    payload: any
  ): Promise<AxiosResponse<any, any>> => {
    const url = `Certificate/${payload}`;
    const response = await axiosInstance.delete<any>(url, payload);
    return response;
  },
  transferContract: async (payload: any): Promise<AxiosResponse<any, any>> => {
    const url = `EmployeeOnBoard/transfer-contract`;
    const response = (await axiosInstance.post)<any>(url, payload);
    return response;
  },
};

export { employeeApi };
