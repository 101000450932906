import { showErrorNotification } from "@utils/notification";
import { showSuccessNotification } from "@utils/notification";
import { createSlice } from "@reduxjs/toolkit";
import { EUserRole } from "models";

export type CurrentUser = {
  id: string;
  role: string | EUserRole;
  userName: string;
  avatar: string;
  email: string;
  phoneNumber?: string;
  claim?: string[];
  employeeInfo?: any;
};
export type TChangePassword = {
  type: string;
  payload: {
    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string;
    onSuccess?: () => void;
    onError?: () => void;
  };
};
type stateType = {
  user: CurrentUser | null;
  getCurrentUserLoading: boolean;
  receiveCurrentUserLoading: boolean;
  logoutLoading: boolean;
  getListUserResponse: any;
  getListUserLoading: boolean;
  changePasswordLoading: boolean;
  getUserProfileLoading: boolean;
  getUserProfileResponse: any;
};

const initialState: stateType = {
  user: null,
  getCurrentUserLoading: false,
  receiveCurrentUserLoading: false,
  logoutLoading: false,
  getListUserResponse: null,
  getListUserLoading: false,
  changePasswordLoading: false,
  getUserProfileLoading: false,
  getUserProfileResponse: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    reducerName(_state, _action) {
      /* TODO */
    },
    getCurrentUser(state) {
      state.getCurrentUserLoading = true;
    },
    getCurrentUserSuccess(state, action) {
      state.user = {
        ...action.payload.data,
        claim: action.payload.data?.claim?.map((item: any) => item?.value),
      };
      localStorage.setItem("isActive", action.payload.data?.isActive);
      state.getCurrentUserLoading = false;
    },
    getCurrentUserFailure(state) {
      state.getCurrentUserLoading = false;
    },
    // get User Profile: email + phone
    getUserProfile(state) {
      state.getUserProfileLoading = true;
    },
    getUserProfileSuccess(state, action) {
      state.getUserProfileResponse = action.payload.data;
      if (state.user && Object.keys(state.user)?.length > 0) {
        state.user = {
          ...state.user,
          phoneNumber: action.payload.data.phoneNumber || "",
          email: action.payload.data.email || "",
        };
      }
      state.getUserProfileLoading = false;
    },
    getUserProfileFailure(state) {
      state.getUserProfileLoading = false;
    },

    updatePhoneNumber(state, action) {
      if (state.user && Object.keys(state.user)?.length > 0) {
        state.user = {
          ...state.user,
          phoneNumber: action.payload || "",
        };
      }
    },
    updateEmail(state, action) {
      if (state.user && Object.keys(state.user)?.length > 0) {
        state.user = {
          ...state.user,
          email: action.payload || "",
        };
      }
    },
    logout(state, _action) {
      state.logoutLoading = true;
    },
    logoutSuccess(state) {
      state.user = null;
      state.logoutLoading = false;
    },
    logoutFailure(state) {
      state.logoutLoading = false;
    },
    getListUserRequest(state, { payload }) {
      state.getListUserLoading = true;
    },
    getListUserSuccess(state, { payload }) {
      state.getListUserResponse = payload.data;
      state.getListUserLoading = false;
    },
    getListUserFailure(state) {
      state.getListUserLoading = false;
    },
    changePassword(state, _action) {
      state.changePasswordLoading = true;
    },
    changePasswordSuccess(state) {
      state.changePasswordLoading = false;
      showSuccessNotification("Change password successful!");
    },
    changePasswordFailure(state) {
      state.changePasswordLoading = false;
      showErrorNotification("Change password failed!");
    },
  },
});

const { actions, reducer } = userSlice;

export { actions as userActions, reducer as userReducer };
