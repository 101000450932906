import { AxiosResponse } from "axios";
import { axiosInstance } from "../../config/axios.config";

type BaseResponse = {
  data: any;
  errors: any;
  isSucceeded: boolean;
};
type actionType = {
  type: string;
  _payload: any;
};

const jobRankApi = {
  getAllJobRank: async ({
    _payload,
  }: actionType): Promise<AxiosResponse<BaseResponse, any>> => {
    const url = "JobRank";
    const response = (await axiosInstance.get)<BaseResponse>(url);

    return response;
  },
};

export { jobRankApi };
