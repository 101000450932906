import { createSlice } from "@reduxjs/toolkit";

type stateType = { getAllLineResponse: any; pagination: any };

const initialState: stateType = { getAllLineResponse: null, pagination: null };

const lineSlice = createSlice({
  name: "line",
  initialState,
  reducers: {
    getAllLineRequest(state, _action) {
      return state;
    },
    getAllLineSuccess(state, { payload }) {
      state.getAllLineResponse = payload.data;
      state.pagination = payload.metaData;
    },
    getAllLineFailure(state) {
      return state;
    },
  },
});

const { actions, reducer } = lineSlice;

export { actions as lineActions, reducer as lineReducer };
